import '../../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {serverDelete, serverPost} from '../../../helpers/server';
import { BaseContext } from '../../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../../components/BaseContainer';
import ContentContainer from '../../../components/ContentContainer';
import ContentBox from '../../../components/ContentBox';
import SubmitButton from '../../../components/SubmitButton';
import BaseForm from '../../../components/BaseForm';
import ConfirmationButton from '../../../components/ConfirmationButton';
import { Button, Row, Col } from 'react-bootstrap';
import {getFeatureDescription, getFeatureDescriptions} from "../../../helpers/integrations";
import classnames from 'classnames';
import Label from "../../../components/Label";
const _ = require('lodash');

function Integrations() {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { company, getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
    const [installedIntegrations, setInstalledIntegrations] = useState([]);
    const [partiallyInstalledIntegrations, setPartiallyInstalledIntegrations] = useState([]);
    const [availableIntegrations, setAvailableIntegrations] = useState([]);

    useEffect(() => {
        setPageTitle(`Integrations`);
    }, []);

    useEffect(() => {
        fetchData();
    }, [company, getApiUrl])

    const fetchData = () => {
        serverPost(getApiUrl("/integrations/available"), { company_id: company.id }).then((res) => {
            if (res) {
                setAvailableIntegrations(_.sortBy(res.results, (r) => r.is_launch_ready ? 0: 1));
            }
        });
        serverPost(getApiUrl("/integrations/installed"), { company_id: company.id, query : {}}).then((res) => {
            if (res) {
                let partial = [];
                let installed = [];
                _.each(res.results, r => {
                    if (_.includes(["ACTIVE", "SETUP_INCOMPLETE"], r.status)) {
                        installed.push(r);
                    } else {
                        partial.push(r);
                    }
                })
                setPartiallyInstalledIntegrations(partial);
                setInstalledIntegrations(installed);
            }
        });
    }

    const onInstallIntegration = async (integration, data) => {
        let targetIntegration = integration;
        if (integration.status === "AVAILABLE") {
            // Haven't started the installation yet
            const installData = {
                key: integration.key,
                live_mode: _.isNil(data.live_mode) ? true: data.live_mode
            }
            const installResult = await serverPost(getApiUrl('/integrations/install'), installData);
            if (installResult) {
                targetIntegration = installResult;
            }
        } else {
            // Already installed or installation started
        }

        navigate(getCompanySpecificUrl(`/integrations/${targetIntegration.id}/setup`));
    }

    const removeIntegration = (integration) => {
        serverDelete(getApiUrl(`/integrations/${integration.id}`)).then((res) => {
            fetchData();
        })
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <h5>{t('integrations.installed_apps.title')}</h5>
                <br/>
                <Row>
                {
                    _.isEmpty(installedIntegrations) &&
                        <span>No integrations installed. Please start by installing one below.</span>
                }
                {
                    _.map(installedIntegrations, (integration, i) => {
                        const descriptions = getFeatureDescriptions(integration);
                        return <Col key={i} md="4">
                            <ContentBox className="integration gap-2">
                                <div className="integration-header mb-1">
                                    <img src={ integration.icon } alt="Integration Logo" className="h-7 w-7 rounded-sm" />
                                    <span className="grow">{ integration.name }</span>
                                    <div>
                                        {
                                            integration.live_mode ?
                                                <Label.Success>Live</Label.Success>
                                            : <Label.Warning>Test</Label.Warning>
                                        }
                                    </div>
                                </div>
                                <div className="flex flex-row gap-2">
                                    {
                                        _.map(descriptions, (f, k) =>
                                            <span key={k}
                                                className={classnames("inline-flex items-center rounded-md px-2 py-1 text-xs font-medium", f.pillClassName)}>{f.shortTitle}</span>
                                        )
                                    }
                                </div>
                                <div className="integration-body">
                                    { integration.description }
                                </div>
                                <div className="integration-footer flex flex-row items-center justify-end gap-2">
                                    {
                                        integration.status === "SETUP_INCOMPLETE" &&
                                            <span className="form-error-message">Onboarding in progress</span>
                                    }
                                    <Link to={getCompanySpecificUrl(`/integrations/${integration.id}/settings`)}><Button variant="primary">Manage</Button></Link>
                                </div>
                            </ContentBox>
                        </Col>
                    })
                }
                </Row>
                <br/>
                {
                    !_.isEmpty(partiallyInstalledIntegrations) &&
                        <>
                            <h5>{t('integrations.partially_installed_apps.title')}</h5>
                            <br/>
                            <Row>
                            {
                                _.map(partiallyInstalledIntegrations, (integration, i) => {
                                    const descriptions = getFeatureDescriptions(integration);
                                    return (<Col key={i} md="4">
                                        <ContentBox className="integration gap-2">
                                            <div className="integration-header mb-1">
                                                <img src={ integration.icon } alt="Integration Logo" className="h-7 w-7 rounded-sm" />
                                                <span className="grow">{ integration.name }</span>
                                                <div>
                                                    {
                                                        integration.live_mode ?
                                                            <Label.Success>Live</Label.Success>
                                                            : <Label.Warning>Test</Label.Warning>
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex flex-row gap-2">
                                                {
                                                    _.map(descriptions, (f, k) =>
                                                        <span key={k}
                                                            className={classnames("inline-flex items-center rounded-md px-2 py-1 text-xs font-medium", f.pillClassName)}>{f.shortTitle}</span>
                                                    )
                                                }
                                            </div>
                                            <div className="integration-body">
                                                { integration.description }
                                            </div>
                                            <div className="integration-footer flex flex-row justify-end gap-1">
                                                <ConfirmationButton
                                                    variant="text-danger"
                                                    title={`Cancel ${integration.name} Setup?`}
                                                    body={"Are you sure you want to cancel the integration setup process?"}
                                                    onConfirm={() => removeIntegration(integration)}
                                                >Cancel</ConfirmationButton>
                                                <Link to={getCompanySpecificUrl(`/integrations/${integration.id}/setup`)}><Button variant="primary" >Finish Setup</Button></Link>
                                            </div>
                                        </ContentBox>
                                    </Col>)
                                })
                            }
                            </Row>
                            <br/>
                        </>
                }
                <h5>{t('integrations.available_apps.title')}</h5>
                <br/>
                <Row>
                {
                    _.map(availableIntegrations, (integration, i) => {
                        const descriptions = getFeatureDescriptions(integration);
                        return (<Col key={i} md="4">
                            <ContentBox className="integration gap-2">
                                <div className="integration-header mb-1">
                                    <img src={integration.icon} alt="Integration Logo" className="h-7 w-7 rounded-sm" />
                                    <span>{integration.name}</span>
                                </div>
                                <div className="flex flex-row gap-2">
                                    {
                                        _.map(descriptions, (f, k) =>
                                            <span key={k}
                                                className={classnames("inline-flex items-center rounded-md px-2 py-1 text-xs font-medium", f.pillClassName)}>{f.shortTitle}</span>
                                        )
                                    }
                                </div>
                                <div className="integration-body">
                                    {integration.description}
                                </div>
                                <div className="integration-footer">
                                    <BaseForm className="flex flex-row items-center gap-2" onSubmit={(data) => onInstallIntegration(integration, data)}>
                                        <div className="grow"></div>
                                        {
                                            integration.has_test_mode &&
                                                <BaseForm.Input
                                                    name="live_mode" type="switch" label="Live Mode"
                                                    value="Live Mode" formClassName="inline" formInputClassName="transparent"
                                                />
                                        }
                                        {
                                            integration.is_launch_ready ?
                                                <SubmitButton>Install</SubmitButton>
                                            : <Button variant="primary" disabled>Coming Soon</Button>
                                        }
                                    </BaseForm>
                                </div>
                            </ContentBox>
                        </Col>)
                    })
                }
                </Row>
            </ContentContainer>
        </BaseContainer>
    );
}

export default Integrations;
