import '../App.scss';
import React, {useContext, useEffect, useState} from "react";
import {serverFetch, serverPost} from "../helpers/server";
import {BaseContext, CustomerContext} from "../helpers/common";
import BaseForm from "./BaseForm";
import {Row} from "react-bootstrap";
import moment from 'moment';
import Chart from "./Chart";
import Loader from "./Loader";
const _ = require("lodash");

function SubscriptionUsage(props) {
    const { getApiUrl } = useContext(BaseContext);
    const customerContext = useContext(CustomerContext);
    const accessToken = customerContext && customerContext.accessToken;
    const [subscriptions, setSubscriptions] = useState([]);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [metrics, setMetrics] = useState([]);
    const [selectedMetric, setSelectedMetric] = useState(null);
    const [metricPeriodMap, setMetricPeriodMap] = useState({});
    const [breakdownLoading, setBreakdownLoading] = useState(null);
    const [usageBreakdown, setUsageBreakdown] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!_.isNil(props.subscriptions)) {
            if (props.subscriptions.length === 1) {
                setSelectedSubscription(props.subscriptions[0]);
            }
            setSubscriptions(props.subscriptions);
        }
    }, [props.subscriptions]);

    useEffect(() => {
        if (!selectedSubscription) {
            return;
        }
        let uniqueMetricIds = [];
        let metricList = [];
        _.each(selectedSubscription.product_pricings, (pp) => {
            _.each(pp.product_metric_pricings, (pmp) => {
                if (!_.includes(uniqueMetricIds, pmp.metric_id)) {
                    metricList.push(pmp.metric);
                    uniqueMetricIds.push(pmp.metric_id);
                }
            })
        })
        setMetrics(metricList);
        setSelectedMetric(metricList[0]);
    }, [selectedSubscription]);

    useEffect(() => {
        fetchMetricUsages();
    }, [selectedSubscription])

    useEffect(() => {
        fetchMetricsUsageBreakdown();
    }, [selectedSubscription, selectedMetric, selectedPeriod])

    const fetchMetricsUsageBreakdown = () => {
        if (!selectedSubscription || !selectedMetric || !selectedPeriod) {
            return;
        }
        setUsageBreakdown(null);
        setBreakdownLoading(true);
        const params = {
            metric_id: selectedMetric.id,
            period: selectedPeriod
        }
        serverPost(getApiUrl(`/subscriptions/${selectedSubscription.id}/usage_breakdown`), params).then((res) => {
            setBreakdownLoading(false);
            if (res && res.usage) {
                setUsageBreakdown(res.usage[0].breakdown);
            }
        });
    }

    const fetchMetricUsages = () => {
        if (!selectedSubscription) {
            return;
        }
        setMetricPeriodMap({});
        setLoading(true);
        serverFetch(getApiUrl(`/subscriptions/${selectedSubscription.id}/usages`)).then((res) => {
            setLoading(false);
            if (res && res.subscription_usages) {
                setMetricPeriodMap(prevMap => {
                    const newMap = {...prevMap};
                    _.each(res.subscription_usages, r => {
                        newMap[`${r.metric.id}`] = _.map(r.usage, (u) => u.period);
                    })
                    return newMap;
                })
            }
        });
    }

    const getApplicablePeriods = () => {
        let applicablePeriods = [];
        if (!_.isNil(selectedMetric)) {
            const allPeriods = metricPeriodMap[selectedMetric.id] || [];
            const reverseSortedPeriods = _.reverse(_.sortBy(allPeriods, p => p.start_date));
            const pastAndCurrentPeriods = _.filter(reverseSortedPeriods, (p) => moment(p.start_date).isBefore(moment()))
            if (pastAndCurrentPeriods.length > 0) {
                applicablePeriods = pastAndCurrentPeriods;
            } else if (allPeriods.length > 0) {
                applicablePeriods = [allPeriods[0]] // if no past periods, show the first future period
            }
        }
        return applicablePeriods
    }

    const onFieldChange = (name, value) => {
        if (name === "metric") {
            setSelectedMetric(_.find(metrics, (m) => m.id === value));
        } else if (name === "period_start") {
            const speriod = _.find(getApplicablePeriods(), p => moment(p.start_date).format("MMM D, YYYY") === value);
            setSelectedPeriod(speriod);
        } else if (name === "subscription") {
            setSelectedSubscription(_.find(subscriptions, s => s.id === value));
        }
    }

    const customMetricPricings = _.filter(metrics, (m) => {
        return m.type === "CUSTOM_METRIC" &&
            !_.startsWith(m.metering_rule.aggregator, "OBJ_") &&
            !_.includes(["CUSTOMER_LAST_EVER", "LAST_EVER_SUM"], m.metering_rule.aggregator)
    })
    const hasUsageMetricPricings = !_.isEmpty(customMetricPricings)
    if (!hasUsageMetricPricings) {
        return null;
    }
    const subscriptionOptions = _.map(subscriptions, (s) => {
        return {
            value: s.id,
            label: s.id
        }
    })

    const metricOptions = _.map(customMetricPricings, (m) => {
        return {
            value: m.id,
            label: m.name
        }
    })

    let periodOptions = [];
    if (!_.isNil(selectedMetric)) {
        let speriods = getApplicablePeriods();
        if (speriods) {
            periodOptions = _.map(getApplicablePeriods(), (p) => {
                return {
                    value: moment(p.start_date).format("MMM D, YYYY"),
                    label: moment(p.start_date).format("MMM D, YYYY") + " - " + moment(p.end_date).format("MMM D, YYYY"),
                }
            })
        }
    }

    const baseline = { key: "value", label: selectedMetric ? selectedMetric.name: "units", type: "integer",
        getValue: (d) => d["value"], visible: true
    }
    return (
        <div>
            {
                (loading || !_.isEmpty(metricPeriodMap)) &&
                    <BaseForm onFieldChange={onFieldChange}>
                        <Row>
                            {
                                subscriptions.length > 1 &&
                                <BaseForm.Input
                                    colSpan="4" type="select" name="subscription" label="Subscription" options={subscriptionOptions} showSearch={false}
                                />
                            }
                            <BaseForm.Input
                                colSpan="4" type="select" name="metric" label="Metric" options={metricOptions} showSearch={false}
                            />
                            <BaseForm.Input
                                colSpan="4" type="select" name="period_start" label="Period" options={periodOptions} showSearch={false}
                            />
                        </Row>
                    </BaseForm>
            }
            <Loader loading={breakdownLoading}>
            {
                _.isNil(usageBreakdown) ?
                    <div>
                        <span className="text-sm text-gray-400">No records found to show subscription usage.</span>
                    </div>
                : <>
                {
                    !_.isNil(usageBreakdown) &&
                        <Chart
                            xKey={(d) => d.period}
                            data={[usageBreakdown]}
                            onDatesChange={(start, end) => {}}
                            defaultDateRange={'This Month'}
                            lines={[baseline]}
                            summaryAggregator={"last"}
                            chartType={"line"}
                            frequency={"DAY"}
                            hideHeader
                        />
                }
                </>
            }
            </Loader>
        </div>
    );
}

export default SubscriptionUsage;
