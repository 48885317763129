import '../../App.scss';
import React, {useEffect, useState, useContext} from 'react';
import {serverFetch, serverPatch, serverPost} from '../../helpers/server';
import { BaseContext, UserContext } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import BaseForm from '../../components/BaseForm';
import ContentContainer from '../../components/ContentContainer';
import {Col, Row} from 'react-bootstrap';
import Loader from "../../components/Loader";
import ContentBox from "../../components/ContentBox";
import SubmitButton from "../../components/SubmitButton";
import Notification from "../../components/Notification";
import Section from "../../components/Section";
import CopyableComponent from "../../components/CopyableComponent";
import sha1 from "js-sha1";
const _ = require('lodash');

function CustomerPortalSettings(props) {
    const { t } = useTranslation('common');
    const { getApiUrl, company, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const { isMapleUser } = useContext(UserContext);

    const [customerPortalEnabled, setCustomerPortalEnabled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState({});
    const [selectedCustomerPortal, setSelectedCustomerPortal] = useState(null);
    const [showCustomerInfo, setShowCustomerInfo] = useState(false);
    const [showPaymentMethods, setShowPaymentMethods] = useState(false);
    const [showSubscriptions, setShowSubscriptions] = useState(false);

    useEffect(() => {
        setPageTitle(`Customer Portal - Manage`);
    }, []);

    const onSearch = () => {
        const limit = 100
        const params = {
            company_id: company.id,
            sort_key: "createdAtDesc",
            pagination: {
                limit: limit
            },
        }
        serverPost(getApiUrl(`/customer_portals/find`), params).then((res) => {
            if (res) {
                if (res.length > 0) {
                    const portal = _.find(res, (r) => !r.customer_id);
                    setSelectedCustomerPortal(portal);
                    setShowCustomerInfo(portal.options.customer_info.show)
                    setShowPaymentMethods(portal.options.payment_methods.show)
                    setShowSubscriptions(portal.options.subscription.show)
                }
            }
            setLoading(false);
        });
    }
    useEffect(() => {
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            if (res) {
                setSettings(res)
            }
        })

        onSearch();
    }, [])

    useEffect(() => {
        setCustomerPortalEnabled(settings.customer_portal_enabled);
    }, [settings]);

    const onFieldChange = (name, value) => {
        if (name === "customer_portal_enabled") {
            updateCompanySettings({ customer_portal_enabled: value })
        } else if (name === "options.customer_info.show") {
            setShowCustomerInfo(value)
        } else if (name === "options.payment_methods.show") {
            setShowPaymentMethods(value)
        } else if (name === "options.subscription.show") {
            setShowSubscriptions(value);
        }
    }

    const updateCompanySettings = async (data) => {
        const result = await serverPatch(getApiUrl(`/settings`), data);
        if (result) {
            if (data.customer_portal_enabled) {
                Notification.Success("Customer portal enabled");
            } else {
                Notification.Success("Customer portal disabled");
            }
            setSettings(result);
            if (data.customer_portal_enabled && _.isNil(selectedCustomerPortal)) {
                let portalResult = await serverPost(getApiUrl(`/customer_portals`), {});
                if (portalResult) {
                    setSelectedCustomerPortal(portalResult);
                    Notification.Success("Customer portal settings successfully updated");
                }
            }
        }
    }

    const updateCustomerPortalSettings = async (data, defaultErrorHandler) => {
        const result = serverPatch(getApiUrl(`/customer_portals/${selectedCustomerPortal.id}`), data, {}, defaultErrorHandler);
        if (result) {
            Notification.Success("Customer portal settings successfully updated");
        }
    }

    const relativeUrl = getCompanySpecificUrl("/portal/login")
    const baseUrl = process.env.REACT_APP_BASE_WEB_URL || "https://app.maplebilling.com";
    const portalLoginLink = `${baseUrl}${relativeUrl}`;
    return (
        <BaseContainer>
            <ContentContainer>
                <ContentBox>
                    <ContentBox.Body>
                        <Loader loading={loading}>
                            <Section title="Customer Portals">
                                <BaseForm initialFormFields={settings} onFieldChange={onFieldChange}>
                                    <Row>
                                        <BaseForm.Input
                                            colSpan="9" name="customer_portal_enabled" label={"Enable Customer Portal"}
                                            description="Manage a hosted page to allow customers to manage their invoices, subscriptions and billing information."
                                            type="switch"
                                        />
                                    </Row>
                                </BaseForm>
                                {
                                    customerPortalEnabled &&
                                        <div className="mt-2">
                                            <p className="text-sm font-semibold">Customer portal link</p>
                                            <p className="text-sm">Here is a shareable customer portal link that will allow your customers to view the portal with their email.</p>
                                            <CopyableComponent value={portalLoginLink}/>
                                            <p className="mt-1">For customer specific portal link, you can get one through the <a target="_blank" href="https://maplebilling.readme.io/reference/get_customer_portal_link">API</a> or from the customer page in Maple.</p>
                                        </div>
                                }
                            </Section>
                            {
                                customerPortalEnabled &&
                                    <Section title="Configure Customer Portal" className="mt-6">
                                        <Row>
                                            <Col md="8">
                                                <hr className="mb-3 bg-gray-400"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="7">
                                                <BaseForm initialFormFields={selectedCustomerPortal} onFieldChange={onFieldChange} onSubmit={updateCustomerPortalSettings}>
                                                    <div className="divide-y divide-gray-200 flex flex-col gap-2">
                                                        <div className="pb-2">
                                                            <Row>
                                                                <BaseForm.Input
                                                                    colSpan="12" type="switch" name="options.customer_info.show" label="Customer Info"
                                                                    description="Allow customers to view their billing information"
                                                                    formClassName="inline"
                                                                />
                                                            </Row>
                                                            {
                                                                showCustomerInfo &&
                                                                <Row className="mt-2">
                                                                    <Col md="1"/>
                                                                    <BaseForm.Input
                                                                        colSpan="11" type="switch" name="options.customer_info.editable" label="Allow updating"
                                                                        description="Allow customers to edit their billing information"
                                                                        formClassName="inline"
                                                                    />
                                                                </Row>
                                                            }
                                                        </div>
                                                        <div className="pb-2">
                                                            <Row className="mt-3">
                                                                <BaseForm.Input
                                                                    colSpan="12" type="switch" name="options.subscription.show" label="Subscriptions"
                                                                    description="Allow customers to view their subscriptions"
                                                                    formClassName="inline"
                                                                />
                                                            </Row>
                                                            {
                                                                isMapleUser && showSubscriptions &&
                                                                <Row className="mt-2">
                                                                    <Col md="1"/>
                                                                    <BaseForm.Input
                                                                        colSpan="11" 
                                                                        type="switch" 
                                                                        name="options.subscription.editable_plan"
                                                                        label="Allow updates"
                                                                        description={
                                                                            <span>
                                                                                Allow customers to update their plan quantities.<br/> 
                                                                                <em>Note: Increase in quantity will immediately charge a prorated amount. 
                                                                                    Decrease in quantity will only affect the quantity (no refund). <br/>
                                                                                    The next invoice will charge the correct amount.</em>
                                                                            </span>
                                                                        }
                                                                        formClassName="inline"
                                                                    />
                                                                </Row>
                                                            }
                                                            {
                                                                showSubscriptions &&
                                                                <Row className="mt-2">
                                                                    <Col md="1"/>
                                                                    <BaseForm.Input
                                                                        colSpan="11" 
                                                                        type="switch" 
                                                                        name="options.subscription.cancellable"
                                                                        label="Allow cancellation"
                                                                        description={
                                                                            <span>
                                                                                Allow customers to cancel their subscriptions.<br/> 
                                                                                <em>Note: Cancellation will happen at the end of their current billing period.</em>
                                                                            </span>
                                                                        }
                                                                        formClassName="inline"
                                                                    />
                                                                </Row>
                                                            }
                                                        </div>
                                                        <div className="pb-2">
                                                            <Row className="mt-3">
                                                                <BaseForm.Input
                                                                    colSpan="12" type="switch" name="options.payment_methods.show" label="Payment Methods"
                                                                    description="Allow customers to view their payment methods on file"
                                                                    formClassName="inline"
                                                                />
                                                            </Row>
                                                            {
                                                                showPaymentMethods &&
                                                                <Row className="mt-2">
                                                                    <Col md="1"/>
                                                                    <BaseForm.Input
                                                                        colSpan="11" type="switch" name="options.payment_methods.creatable"
                                                                        label="Allow updating"
                                                                        description="Allow customers to add and remove their payment methods"
                                                                        formClassName="inline"
                                                                    />
                                                                </Row>
                                                            }
                                                        </div>
                                                        <div className="pb-2">
                                                            <Row className="mt-3">
                                                                <BaseForm.Input
                                                                    colSpan="12" type="switch" name="options.invoice.show" label="Invoices"
                                                                    description="Allow customers to view their invoice history"
                                                                    formClassName="inline"
                                                                />
                                                            </Row>
                                                        </div>
                                                        {<div className="pb-2">
                                                            <Row className="mt-3">
                                                                <BaseForm.Input
                                                                    colSpan="12" type="switch" name="options.credits.show" label="Credits"
                                                                    description="Allow customers to view their credit balance and usage"
                                                                    formClassName="inline"
                                                                />
                                                            </Row>
                                                        </div>}
                                                        <div className="pb-2">
                                                            <Row className="mt-3">
                                                                <BaseForm.Input
                                                                    colSpan="12" type="text" name="business_info.message" label="Message"
                                                                    description="An optional title to be shown on the left panel."
                                                                    placeholder={`${company.name} partners with Maple for simplified billing.`}
                                                                />
                                                                <BaseForm.Input
                                                                    colSpan="12" type="text" name="business_info.terms_of_service_url" label="Terms of service (optional)"
                                                                    description="An optional terms of service link to be shown."
                                                                />
                                                                <BaseForm.Input
                                                                    colSpan="12" type="text" name="business_info.privacy_policy_url" label="Privacy Policy (optional)"
                                                                    description="An optional privacy policy link to be shown."
                                                                />
                                                                <BaseForm.Input
                                                                    colSpan="12" type="text" name="redirect_url" label="Redirect URL (optional)"
                                                                    description="An optional url to redirect the customer to, when the back button is pressed."
                                                                    placeholder="https://www.google.com"
                                                                />
                                                            </Row>
                                                        </div>
                                                        <div className="pt-3">
                                                            <SubmitButton errorOnRight>Update</SubmitButton>
                                                        </div>
                                                    </div>
                                                </BaseForm>
                                            </Col>
                                        </Row>
                                    </Section>
                            }
                        </Loader>
                    </ContentBox.Body>
                </ContentBox>
            </ContentContainer>
        </BaseContainer>
    );
}

export default CustomerPortalSettings;
