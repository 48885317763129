import '../../App.scss';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { serverPost } from '../../helpers/server';
import {
    BaseContext,
    currencyFormatFromPrice, getCampaignTypeLabel,
    getConfigActionTypeKey,
    renderCampaignStatusLabel
} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import Columns from '../../components/Columns';
import MapleTable from '../../components/MapleTable';
import InfiniteScroll from "react-infinite-scroll-component";
import SortableTableHeader from "../../components/SortableTableHeader";
import MapleTableHeaderWithActions from "../../components/MapleTableHeaderWithActions";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import Link from "../../components/Link";

const _ = require('lodash');

function Campaigns(props) {
    const navigate = useNavigate();
    const {t} = useTranslation('common');
    const {company, getApiUrl, getCompanySpecificUrl, setPageTitle} = useContext(BaseContext);
    const [campaigns, setCampaigns] = useState([]);

    const [activeNav, setActiveNav] = useState("payment_recovery");
    const [meta, setMeta] = useState({});
    const [sort, setSort] = useState("createdAtDesc");
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [filters, setFilters] = useState([]);

    const cachedSelectedFilters = useMemo(() => {
        const cachedString = localStorage.getItem(company.id + "_filter_cache_campaigns");
        if (cachedString) {
            return JSON.parse(cachedString);
        }
    }, [])
    const defaultSelectedFilters = useMemo(() => {
        return cachedSelectedFilters || {};
    }, []);
    const [selectedFilters, setSelectedFilters] = useState(cachedSelectedFilters || defaultSelectedFilters);

    useEffect(() => {
        setPageTitle(`Email Campaigns`);
    }, []);

    useEffect(() => {
        onSearch(true);
    }, [activeNav, selectedFilters, sort]);

    useEffect(() => {
        const typeOptions = [
            {value: "payment_recovery", label: "Payment Recovery"},
            {value: "invoice_reminder", label: "Invoice Reminders"},
            {value: "payment_method_expired", label: "Card Expiry Reminders"},
        ];

        const statusOptions = [
            { value: "ACTIVE", label: "Active" },
            { value: "COMPLETED", label: "Completed" },
            { value: "RECOVERED", label: "Recovered" },
            { value: "STOPPED", label: "Stopped" },
        ];

        setFilters([
            {title: "Type", type: "multi-select", name: "type", options: typeOptions},
            {title: "Status", type: "multi-select", name: "status", options: statusOptions},
        ]);
    }, []);

    const onSearch = (restart = true) => {
        const limit = 100;
        const params = {
            company_id: company.id,
            sort_key: sort || "createdAtDesc",
            pagination: {
                from_key: restart ? null : fromKey,
                limit: limit
            },
            query: {
                ...selectedFilters,
                status: _.isEmpty(selectedFilters.status) ? null : selectedFilters.status,
                type: _.isEmpty(selectedFilters.type) ? ["payment_recovery", "invoice_reminder", "payment_method_expired"] : selectedFilters.type,
            },
            include_meta: restart
        };
        serverPost(getApiUrl("/campaigns/find"), params).then((res) => {
            if (res) {
                const results = (res.results && res.results.length) > 0 ? res.results : [];
                if (restart) {
                    setCampaigns(results);
                    setMeta(res.meta);
                } else {
                    setCampaigns(_.concat(campaigns, results));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    const onNavClick = (tabId) => {
        setActiveNav(tabId);
    };

    const onClickCampaignRow = (e, campaignId) => {
        navigate(getCompanySpecificUrl(`/campaign/${campaignId}`));
    };

    const onClickCustomerCell = (e, customerId) => {
        e.stopPropagation();
        navigate(getCompanySpecificUrl(`/customer/${customerId}`));
    };

    const onClickInvoiceCell = (e, invoiceId) => {
        e.stopPropagation();
        navigate(getCompanySpecificUrl(`/invoice/${invoiceId}`));
    };

    const onParamsChange = (params) => {
        if (!_.isEqual({...selectedFilters, sort}, params)) {
            const newFilters = {...params};
            setSelectedFilters(newFilters);
            localStorage.setItem(company.id + "_filter_cache_campaigns", JSON.stringify(newFilters));
        }
    };

    const renderNextStep = (row) => {
        if (row.status === "ACTIVE" && row.next_action_type) {
            return t(getConfigActionTypeKey(row.next_action_type));
        } else {
            return "-";
        }
    };

    return (
        <BaseContainer>
            <ContentContainer>
                <InfiniteScroll
                    dataLength={campaigns.length}
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget="content-wrapper"
                >
                    <Section title="Email Campaigns">
                        {
                            <MapleTable>
                                {
                                    <MapleTableHeaderWithActions
                                        showSearch={true}
                                        searchPlaceholder="Search Campaigns"
                                        showFilters={true}
                                        filters={filters}
                                        meta={meta}
                                        defaultSelectedFilters={defaultSelectedFilters}
                                        onParamsChange={onParamsChange}
                                    />
                                }
                                <MapleTable.Content>
                                    <thead>
                                    <tr>
                                        <MapleTable.TH>Customer</MapleTable.TH>
                                        <MapleTable.TH>Invoice</MapleTable.TH>
                                        <MapleTable.TH>Type</MapleTable.TH>
                                        <MapleTable.TH>Status</MapleTable.TH>
                                        <MapleTable.TH>Campaign Progress</MapleTable.TH>
                                        <MapleTable.TH>Next Action</MapleTable.TH>
                                        <MapleTable.TH>Time Delinquent</MapleTable.TH>
                                        <SortableTableHeader
                                            onSortChange={setSort} sortKeyUp="amountAsc" sortKeyDown="amountDesc"
                                            currentSort={sort} className="no-stretch">
                                            Amount
                                        </SortableTableHeader>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {
                                        _.map(campaigns, (campaign, i) =>
                                            <tr key={i} className="cursor-pointer"
                                                onClick={(e) => onClickCampaignRow(e, campaign.id)}>
                                                <td className="">
                                                    {
                                                        campaign.customer &&
                                                        <Columns.CustomerName customer={campaign.customer}/>
                                                    }
                                                </td>
                                                <td>
                                                {
                                                    campaign.invoice &&
                                                        <Link href={getCompanySpecificUrl(`/invoice/${campaign.invoice.uuid}`)}>{ campaign.invoice.number }</Link>
                                                }
                                                </td>
                                                <td>{getCampaignTypeLabel(campaign.type)}</td>
                                                <td>{renderCampaignStatusLabel(campaign)}</td>
                                                <td>{`${campaign.current_step}/${campaign.total_steps} steps`}</td>
                                                <td>{renderNextStep(campaign)}</td>
                                                <td>
                                                    {
                                                        moment().diff(moment(campaign.time_from_reference_data), "days") > 0 ?
                                                            `Overdue by ${moment().diff(moment(campaign.time_from_reference_data), "days")} days`
                                                            : `Overdue`
                                                    }
                                                </td>
                                                <td className="no-stretch"
                                                    onClick={(e) => onClickInvoiceCell(e, campaign.invoice.uuid)}>
                                                    {campaign.invoice && currencyFormatFromPrice(campaign.amount)}
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        hasMore &&
                                        <tr>
                                            <td colSpan={8} className="text-center">
                                                <div className="spinner-border text-secondary"/>
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </MapleTable.Content>
                            </MapleTable>
                        }
                    </Section>
                </InfiniteScroll>
            </ContentContainer>
        </BaseContainer>
    );
}

export default Campaigns;
