import '../../App.scss';
import React, {useEffect, useState, useContext, createRef, useRef} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import { serverFetch, serverPost } from '../../helpers/server';
import {
    BaseContext, getCustomerNameOrEmail,
    getInvoicePaymentTermFromDueDateFromCreation,
    getInvoicePaymentTermOptions, getPaymentMethodDescription, removeHtmlTags, updateFormField,
    UserContext,
} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import BaseForm from '../../components/BaseForm';
import ContentContainer from '../../components/ContentContainer';
import { Row, Col, Alert } from 'react-bootstrap';
import moment from 'moment';
import ProductPricingSelection from "../../components/ProductPricingSelection";
import SubmitButton from "../../components/SubmitButton";
import Loader from "../../components/Loader";
import ContentBox from "../../components/ContentBox";
import CustomerInput from "../../components/CustomerInput";
import Link from "../../components/Link";
import classnames from 'classnames';
const _ = require('lodash');

function SubscriptionCreate(props) {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { getApiUrl, getCompanySpecificUrl, company, setPageTitle } = useContext(BaseContext);
    const { isMapleUser } = useContext(UserContext);
    const [ searchParams ] = useSearchParams();
    let defaultCustomerId = searchParams.get('customer');
    let dealID = searchParams.get('did');
    let integrationID = searchParams.get('iid');

    const [showAdvanced, setShowAdvanced] = useState(false);
    const [productPricings, setProductPricings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [coupons, setCoupons] = useState([]);
    const [settings, setSettings] = useState({});
    const [error, setError] = useState(null);
    const [isInPast, setIsInPast] = useState(false);
    const [defaultCustomer, setDefaultCustomer] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [autoCharges, setAutoCharges] = useState(true);
    const [invoicePaymentTerms, setInvoicePaymentTerms] = useState(null);
    const [isTrial, setIsTrial] = useState(false);
    const [prefetchDealDetails, setPrefetchDealDetails] = useState(null);
    const [preselectedProductPricingIds, setPreselectedProductPricingIds] = useState([]);
    const [initialFields, setInitialFields] = useState({
        start_date: moment(),
        term: {
            count: 1,
            frequency: "YEAR"
        },
        payment_method_id: null,
    });
    const [subscriptionPaymentSettingEnabled, setSubscriptionPaymentSettingEnabled] = useState(false);
    const [paymentMethodOptions, setPaymentMethodOptions] = useState([{
        value: null,
        label: "Use default customer payment method"
    }]);
  
    const pricingsRef = useRef();

    useEffect(() => {
        setPageTitle(`Subscription - Create`);
    }, []);

    useEffect(() => {
        if (defaultCustomerId) {
            serverFetch(getApiUrl(`/customers/${defaultCustomerId}`), { skipCache: true }).then((res) => {
                setDefaultCustomer(res);
                setSelectedCustomer(res);
                setInitialFields(prevFields => {
                    const newFields = {...prevFields};
                    newFields.customer = res;
                    return newFields;
                });
            });
            serverFetch(getApiUrl(`/customers/${defaultCustomerId}/payment_methods`), {}).then((res) => {
                if (res) {
                    setPaymentMethods(res.methods);
                }
            });
        }
    }, [defaultCustomerId])

    useEffect(() => {
        if (dealID && integrationID && isMapleUser) {
            serverFetch(getApiUrl(`/proposals/prefetch/${integrationID}/${dealID}`)).then((res) => {
                if (res) {
                    setPrefetchDealDetails(res);
                }
            })
        }
    }, [dealID, integrationID]);

    useEffect(() => {
        if (prefetchDealDetails) {
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                if (prefetchDealDetails.customer) {
                    newFields.customer = prefetchDealDetails.customer;
                }
                if (prefetchDealDetails.deal) {
                    if (prefetchDealDetails.deal.product_pricings) {
                        setPreselectedProductPricingIds(_.map(prefetchDealDetails.deal.product_pricings, pp => pp.product_pricing_id));
                        const prefetchConfig = {};
                        const prefetchDiscounts = {};
                        _.each(prefetchDealDetails.deal.product_pricings, (dealPP) => {
                            const pp = _.find(productPricings, p => p.id === dealPP.product_pricing_id);
                            if (pp) {
                                let percentageDiscount = null;
                                if (dealPP.discounts && dealPP.discounts.length > 0) {
                                    if (dealPP.discounts[0].type === "DISCOUNT_PERCENT") {
                                        percentageDiscount = dealPP.discounts[0].percent * 100
                                    }
                                }
                                _.each(pp.product_metric_pricings, (pmp) => {
                                    prefetchConfig[pmp.id] = {
                                        num_licenses: dealPP.num_licenses
                                    }
                                    if (percentageDiscount) {
                                        prefetchDiscounts[pmp.item_pricing_id] = {
                                            percentage: percentageDiscount
                                        }
                                    }
                                })
                            }
                        })
                        newFields.phases = [{
                            config: prefetchConfig,
                            discounts: prefetchDiscounts,
                        }]
                    }
                }
                return newFields;
            });
        }
    }, [prefetchDealDetails, productPricings])

    const setPaymentMethods = (paymentMethods) => {
        const options = _.map(paymentMethods, (pm) => {
            return {
                value: pm.id,
                label: getPaymentMethodDescription(t, pm)
            }
        })
        options.unshift({
            value: null,
            label: "Use default customer payment method"
        })
        setInitialFields(prevFields => {
            const newFields = {...prevFields};
            newFields.payment_method_id = null;
            return newFields;
        });
        setPaymentMethodOptions(options)
    }

    useEffect(() => {
        setInitialFields(prevFields => {
            const newFields = {...prevFields};
            newFields.start_date = moment();
            return newFields;
        });

        const limit = 100
        const params = {
            company_id: company.id,
            sort_key: "createdAtDesc",
            pagination: {
                limit: limit
            },
        }
        serverPost(getApiUrl("/product_pricings/find"), params).then(async (res) => {
            if (res) {
                const results = res.results || [];
                setProductPricings(results);
            }
            setLoading(false);
        });

        serverPost(getApiUrl("/coupons/find"), { companyId: company.id }).then((res) => {
            setCoupons(res);
        });
    }, [company, getApiUrl])

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
            setInvoicePaymentTerms(getInvoicePaymentTermFromDueDateFromCreation(res.invoice_due_date_from_creation));
            if (_.isNil(res.payment_config)) {
                setAutoCharges(false);
                setInitialFields(prevFields => {
                    const newFields = {...prevFields};
                    newFields.auto_charges = false;
                    newFields.invoice_payment_terms = getInvoicePaymentTermFromDueDateFromCreation(res.invoice_due_date_from_creation);
                    newFields.invoice_due_date_from_creation = res.invoice_due_date_from_creation;
                    return newFields;
                });
            } else {
                setAutoCharges(res.auto_charges);
                setInitialFields(prevFields => {
                    const newFields = {...prevFields};
                    newFields.auto_charges = res.auto_charges;
                    newFields.invoice_payment_terms = getInvoicePaymentTermFromDueDateFromCreation(res.invoice_due_date_from_creation);
                    newFields.invoice_due_date_from_creation = res.invoice_due_date_from_creation;
                    return newFields;
                });
            }
        });
    }, []);

    const onFieldChange = async (name, value) => {
        if (name === "start_date") {
            setIsInPast(moment(value).utc().isBefore(moment().utc().startOf('day')));
        } else if (name === "invoice_payment_terms") {
            setInvoicePaymentTerms(value);
        } else if (name === "customer") {
            setSelectedCustomer(value);
            const res = await serverFetch(getApiUrl(`/customers/${value.id}/payment_methods`), {})
            if (res) {
                setPaymentMethods(res.methods);
            } else {
                setPaymentMethods([]);
            }
        } else if (name === "auto_charges") {
            setAutoCharges(value)
        } else if (name === "trial") {
            setIsTrial(value)
        } else if (name === "invoice_payment_instructions_enabled") {
            setSubscriptionPaymentSettingEnabled(value)
        }
        pricingsRef.current?.onFieldChange(name, value);
        setInitialFields(prevFields => {
            return updateFormField(prevFields, name, value);
        })
    }

    const toggleShowAdvanced = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setShowAdvanced(!showAdvanced);
    }

    const addSubscription = async (subscriptionFields, defaultErrorHandler) => {
        let pricingData = {};
        if (pricingsRef.current) {
            const validationResult = pricingsRef.current.validate(subscriptionFields);
            if (!_.isNil(validationResult)) {
                setError(validationResult)
                return;
            }

            pricingData = pricingsRef.current.getPricingSelectionFields(subscriptionFields);
        }
        setError(null);
        const startOfToday = moment().startOf('day');
        const startOfStartDate = moment(subscriptionFields.start_date).startOf('day');
        const isToday = startOfToday.isSame(startOfStartDate)
        const startDate = isToday ? moment(): moment(subscriptionFields.start_date)
        const data = {
            ...pricingData,
            company_id: subscriptionFields.company_id,
            auto_renews: _.isEmpty(subscriptionFields.end_date),
            auto_charges: subscriptionFields.auto_charges,
            term: {
                frequency: subscriptionFields.term.frequency,
                count: parseInt(subscriptionFields.term.count)
            },
            start_date: startDate.format(),
            billing_anchor_date: subscriptionFields.billing_anchor_date,
            end_date: _.isEmpty(subscriptionFields.end_date) ? null : subscriptionFields.end_date,
            renewal_date: _.isEmpty(subscriptionFields.renewal_date) ? null : subscriptionFields.renewal_date,
            invoice_due_date_from_creation: subscriptionFields.invoice_due_date_from_creation,
            generate_past_invoice: subscriptionFields.generate_past_invoice,
            trial: subscriptionFields.trial,
        }
        if (subscriptionFields.trial) {
            data['trial_term'] = {
                frequency: 'DAY',
                count: subscriptionFields.num_trial_days,
            }
        }
        if (subscriptionFields.billing_anchor_date) {
            console.log("Billing anchor date is set");
            data['billing_anchor_date'] = subscriptionFields.billing_anchor_date
        }
        if (subscriptionFields.customer.integration_id) {
            data['integration_customer'] = {
                import_id: subscriptionFields.customer.id,
                integration_id: subscriptionFields.customer.integration_id,
                integration_object_type: subscriptionFields.customer.integration_object_type
            }
        } else {
            data['customer_id'] = subscriptionFields.customer.id;
            data['parent_billed'] = subscriptionFields.parent_billed;
        }
        if (!_.isNil(subscriptionFields.invoice_payment_terms)) {
            if (subscriptionFields.invoice_payment_terms !== "custom") {
                data.invoice_due_date_from_creation = parseInt(subscriptionFields.invoice_payment_terms);
            }
        }
        if (subscriptionFields.payment_method_id) {
            data['payment_method_id'] = subscriptionFields.payment_method_id
        }
        if (subscriptionFields.invoice_payment_instructions_enabled) {
            data['invoice_payment_instructions'] = removeHtmlTags(subscriptionFields.invoice_payment_instructions).trim().length ? subscriptionFields.invoice_payment_instructions : "";
        }
        console.log("The subscription data is " + JSON.stringify(data));
        const subscriptionResult = await serverPost(getApiUrl('/subscriptions'), data, {}, defaultErrorHandler);
        if (subscriptionResult) {
            navigate(getCompanySpecificUrl(`/subscription/${subscriptionResult.id}`), { replace: true })
        }
    }

    const planLengthOptions = [
        { value: "DAY", label: "Day" },
        { value: "WEEK", label: "Week" },
        { value: "MONTH", label: "Month" },
        { value: "QUARTER", label: "Quarter" },
        { value: "BI_ANNUAL", label: "Bi-Annual" },
        { value: "YEAR", label: "Year" },
    ]

    const paymentMechanismOptions = [
        { value: true, label: "Automatically charge a payment method on file",
            disabled: _.isNil(settings.payment_config), disabledDescription: "* Payment Provider needs to be setup" },
        { value: false, label: "Email invoice to customer manually" },
    ]

    const parentBilledOptions = [
        selectedCustomer && selectedCustomer.parent_customer && { value: true, label: `Parent - ${getCustomerNameOrEmail(selectedCustomer.parent_customer)}` },
        selectedCustomer && { value: false, label: `Customer - ${getCustomerNameOrEmail(selectedCustomer)}` },
    ]

    return (
        <BaseContainer>
            <ContentContainer>
                <ContentBox>
                    <ContentBox.Body>
                        <Loader loading={loading || (defaultCustomerId && !defaultCustomer)}>
                        {
                            _.isEmpty(productPricings) &&
                            <div className="d-flex flex-column">
                                <div className="align-items-center justify-content-center text-center" style={{ marginTop: "30px" }}>
                                    <Row>
                                        <Col md={{ span: 8, offset: 2 }}>
                                            <div className="body2">Hi there!</div>
                                            <br/>
                                            <p>Before we create a subscription, we will need to create at least one pricing for a
                                                product. You can get <Link href={getCompanySpecificUrl("/products")}>started here</Link></p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        }
                        {
                            !_.isEmpty(productPricings) &&
                            <div>
                                <h4>Create Subscription</h4>
                                <br/>
                                <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={addSubscription}>
                                <Row>
                                    <CustomerInput colSpan="6" defaultCustomer={defaultCustomer} allowLeads required />
                                    <BaseForm.Input colSpan="6" name="start_date" label={t('subscriptions.start_date') + " (local timezone)"} type="date"
                                                    includeTime={true} required />
                                </Row>
                                <Row>
                                    {
                                        selectedCustomer && selectedCustomer.parent_customer_id &&
                                            <BaseForm.Input
                                                colSpan="6" name="parent_billed" label={"Bill To"}
                                                type="select" options={parentBilledOptions} showSearch={false} />
                                    }
                                    <Link onClick={toggleShowAdvanced}>Advanced Options</Link>
                                </Row>
                                <Row className={classnames(showAdvanced ? "": "hide", "")}>
                                    <Col md="12">
                                        <div className="bg-gray-100 py-2 px-3 rounded-md">
                                        <Row>
                                            <BaseForm.Input
                                                colSpan="6" name="billing_anchor_date" label={t('subscriptions.billing_anchor_date') + " (optional, local timezone)"}
                                                type="date" minDate={moment()} includeTime />
                                            <BaseForm.Input colSpan="6" name="end_date" label={t('subscriptions.end_date') + " (optional, local timezone)"} type="date" minDate={moment()} includeTime />
                                            <BaseForm.Input colSpan="6" name="auto_charges" label={t('subscriptions.payment_mechanism')} type="select"
                                                            options={paymentMechanismOptions} showSearch={false} />
                                            <BaseForm.Input colSpan="6" type="select" name="payment_method_id" label={"Payment Method"} options={paymentMethodOptions} showSearch={false}/>
                                            {
                                                !autoCharges &&
                                                <>
                                                    <BaseForm.Input type="select" colSpan="3" name="invoice_payment_terms" label="Invoice Payment Terms" options={getInvoicePaymentTermOptions()} showSearch={false} />
                                                    {
                                                        invoicePaymentTerms === "custom" &&
                                                        <BaseForm.Input type="number" colSpan="3" name="invoice_due_date_from_creation" label="Due Date (in days)" step="1" min="0" />
                                                    }
                                                </>
                                            }
                                        </Row>
                                        {isMapleUser && (
                                            <Row className="flex-col mt-2">
                                                <BaseForm.Input
                                                    colSpan="9"
                                                    type="switch"
                                                    label="Customize Payment Instructions"
                                                    name="invoice_payment_instructions_enabled"
                                                    required
                                                    description={
                                                        <span>
                                                            These instructions will override the payment instructions set at the company-level for all
                                                            invoices related to this subscription.
                                                        </span>
                                                    }
                                                />
                                                {subscriptionPaymentSettingEnabled && (
                                                    <BaseForm.Input
                                                        colSpan="9"
                                                        name="invoice_payment_instructions"
                                                        errorLabel="Payment instructions content"
                                                        type="editor"
                                                        height="300"
                                                    />
                                                )}
                                            </Row>
                                        )}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg="6">
                                        <BaseForm.InputGroup label="Term">
                                            <BaseForm.Number name="term.count" min="1" />
                                            <BaseForm.Divider />
                                            <BaseForm.SingleSelect
                                                name="term.frequency" options={planLengthOptions} showSearch={false}
                                                fullWidth
                                            />
                                        </BaseForm.InputGroup>
                                    </Col>
                                    <Col lg="3" className="flex items-end">
                                        <BaseForm.Input
                                            type="switch" name="trial" label={"Free trial days"} outerInputClassName="grow"
                                        />
                                    </Col>
                                    {
                                        isTrial &&
                                            <BaseForm.Input
                                                colSpan={3} type="number" name="num_trial_days" label="Trial Days"
                                                step="1" min="0" validations={{ required: true, gt: 0 }} />
                                    }
                                </Row>
                                <br/>
                                <ProductPricingSelection
                                    ref={pricingsRef} productPricings={productPricings} coupons={coupons}
                                    preselectedProductPricingIds={preselectedProductPricingIds}
                                    allowDiscounts={true} requireRecurring allowMinimumSpend/>
                                    {
                                        error &&
                                        <div className="form-error-message">{ error }</div>
                                    }
                                    {
                                        isInPast &&
                                        <Alert variant="warning p-3">
                                            <div className="d-flex flex-row gap-3">
                                                <div className="flex-grow-1">
                                                    <p className="text-sm font-semibold">Subscription in the past</p>
                                                    <div className="body1">This subscription starts in the past. Would you like to create an invoice for the start of the subscription?</div>
                                                </div>
                                                <div className="flex-shrink-0 d-flex align-items-center">
                                                    <BaseForm.Input formClassName="inline" name="generate_past_invoice"
                                                                    type="switch" label="Generate Invoice" />
                                                </div>
                                            </div>
                                        </Alert>
                                    }
                                    <SubmitButton errorOnRight>Create</SubmitButton>
                                </BaseForm>
                            </div>
                        }
                        </Loader>
                    </ContentBox.Body>
                </ContentBox>
            </ContentContainer>
        </BaseContainer>
    );
}

export default SubscriptionCreate;
