import '../App.scss';
import React from 'react';
import { Image } from 'react-bootstrap';
import classnames from "classnames";
import {getCustomerNameOrEmail} from "../helpers/common";
const _ = require('lodash');

function Columns(props) {
    return (
        <></>
    );
}

function CustomerName(props) {
    const name = getCustomerNameOrEmail(props.customer) || "";
    const parts = name.split(" ");
    let nameLabel = "";
    if (parts.length > 1) {
        nameLabel = parts[0].substring(0, 1) + parts[1].substring(0, 1);
    } else {
        nameLabel = name.substring(0, 2);
    }
    return (
        <div>
            <div className="flex flex-row items-center gap-2">
                {
                    !props.hideAvatar &&
                        <div className="profile-icon">
                            { nameLabel }
                        </div>
                }
                <div className="flex flex-col">
                    <p className="font-medium text-gray-700">{ props.customer.org_name || props.customer.name || props.customer.identifier }</p>
                    <p className="font-normal text-gray-500 break-all">{ props.customer.email }</p>
                    {
                        !_.isNil(props.customer.parent_customer) && !_.isEmpty(props.customer.parent_customer) && !props.excludeParentName &&
                            <p className="text-xs font-normal text-gray-500 break-all italic">Parent: { getCustomerNameOrEmail(props.customer.parent_customer) }</p>
                    }
                </div>
            </div>
        </div>
    )
}
Columns.CustomerName = CustomerName;

function CustomerAvatar(props) {
    const name = props.customer.name || props.customer.email;
    const parts = name.split(" ");
    let nameLabel = "";
    if (parts.length > 1) {
        nameLabel = parts[0].substring(0, 1) + parts[1].substring(0, 1);
    } else {
        nameLabel = name.substring(0, 2);
    }
    return (
        <div>
            <div className="d-flex flex-row align-items-center">
                <div className="profile-icon">
                    { nameLabel }
                </div>
            </div>
        </div>
    )
}
Columns.CustomerAvatar = CustomerAvatar;

function CompanyUserName(props) {
    const companyUser = props.companyUser;
    const name = companyUser.user.name || "";
    const email = companyUser.user.email || "";
    const nameForLabel = name || email;
    const parts = nameForLabel.split(" ");
    let nameLabel = "";
    if (parts.length > 1) {
        nameLabel = parts[0].substring(0, 1) + parts[1].substring(0, 1);
    } else {
        nameLabel = nameForLabel.substring(0, 2);
    }
    let profilePhoto = props.companyUser.image_url;
    return (
        <div>
            <div className="flex flex-row items-center gap-2">
                {
                    !props.hideAvatar &&
                    <div className="profile-icon">
                        {
                            profilePhoto ?
                                <Image className="rounded-full opacity-70" src={profilePhoto} />
                                : nameLabel
                        }
                    </div>
                }
                <div className="flex flex-col">
                    {
                        name &&
                            <p className="font-medium text-gray-700">{ name }</p>
                    }
                    <p className="font-normal text-gray-500 break-all">{ email }</p>
                </div>
            </div>
        </div>
    )
}
Columns.CompanyUserName = CompanyUserName;

function CompanyUserAvatar(props) {
    const name = props.companyUser.user.name || props.companyUser.user.email;
    const parts = name.split(" ");
    let nameLabel = "";
    if (parts.length > 1) {
        nameLabel = parts[0].substring(0, 1) + parts[1].substring(0, 1);
    } else {
        nameLabel = name.substring(0, 2);
    }
    let profilePhoto = props.companyUser.image_url;
    return (
        <div>
            <div className="d-flex flex-row align-items-center">
                <div className="profile-icon">
                    {
                        profilePhoto ?
                            <Image className="rounded-full opacity-70" src={profilePhoto} />
                        : nameLabel
                    }
                </div>
            </div>
        </div>
    )
}
Columns.CompanyUserAvatar = CompanyUserAvatar;

function CustomerName2(props) {
    const name = getCustomerNameOrEmail(props.customer);
    const parts = name.split(" ");
    let nameLabel = "";
    if (parts.length > 1) {
        nameLabel = parts[0].substring(0, 1) + parts[1].substring(0, 1);
    } else {
        nameLabel = name.substring(0, 2);
    }
    return (
        <div className="flex items-center">
            <div className="h-11 flex-shrink-0">
            </div>
            <div>
                <div className="font-medium text-gray-900">{ props.customer.org_name || props.customer.name }</div>
                <div className="mt-1 text-gray-500">{ props.customer.email }</div>
            </div>
        </div>
    )
}
Columns.CustomerName2 = CustomerName2;

function CustomerAvatar2(props) {
    const name = props.customer.name || props.customer.email;
    const parts = name.split(" ");
    let nameLabel = "";
    if (parts.length > 1) {
        nameLabel = parts[0].substring(0, 1) + parts[1].substring(0, 1);
    } else {
        nameLabel = name.substring(0, 2);
    }
    return (
        <span className={classnames("inline-flex h-9 w-9 items-center justify-center rounded-full bg-primary-600", props.className)}>
            <span className="text-sm font-medium leading-none text-white">{ nameLabel }</span>
        </span>
    )
}
Columns.CustomerAvatar2 = CustomerAvatar2;

function CustomerAvatarGroup(props) {
    return (
        <>
            {
                _.map(props.users, (user, i) =>
                    <Columns.CustomerAvatar2 key={i} customer={ user } className={classnames("border border-white", i !== 0 ? "-ml-4": "")}/>
                )
            }
        </>
    )
}
Columns.CustomerAvatarGroup = CustomerAvatarGroup;

export default Columns;
