import '../../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { serverFetch, serverDelete, serverPost } from '../../../helpers/server';
import { BaseContext, getRoleDescription } from '../../../helpers/common';
import InviteCompanyUserModal from '../../../components/modals/InviteCompanyUserModal';
import ConfirmationButton from '../../../components/ConfirmationButton';
import Columns from '../../../components/Columns';
import BaseContainer from '../../../components/BaseContainer';
import ContentContainer from '../../../components/ContentContainer';
import Section from '../../../components/Section';
import Notification from '../../../components/Notification';
import MapleTable from '../../../components/MapleTable';
import Label from '../../../components/Label';
import { Button } from 'react-bootstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import UpdateCompanyUserModal from "../../../components/modals/UpdateCompanyUserModal";
import useGetMembers from "../../../helpers/hooks/api/useGetMembers";
const _ = require('lodash');

function Team(props) {
    const { t } = useTranslation('common');
    const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
    const [showInviteMemberModal, setShowInviteMemberModal] = useState(false);
    const [showUpdateMemberModal, setShowUpdateMemberModal] = useState(false);
    const [companyUserToUpdate, setCompanyUserToUpdate] = useState(null);
    const { members, fetchMembers } = useGetMembers();

    useEffect(() => {
        setPageTitle(`Team`);
    }, []);

    useEffect(() => {
        fetchMembers();
    }, []);

    const onModalClose = () => {
        setShowInviteMemberModal(false);
        setShowUpdateMemberModal(false);
        fetchMembers(true);
    }

    const onInviteUser = () => {
        setShowInviteMemberModal(true);
    }

    const revokeAccess = (companyUser) => {
        serverDelete(getApiUrl(`/users/${companyUser.id}`)).then((res) => {
            fetchMembers(true);
        })
    }

    const revokeInvite = (companyUser) => {
        serverDelete(getApiUrl(`/users/${companyUser.id}`)).then((res) => {
            fetchMembers(true);
        })
    }

    const resendInvite = (companyUser) => {
        serverPost(getApiUrl(`/invite/${companyUser.id}`)).then((res) => {
            Notification.Success("Invitation Resent Successfully");
        })
    }

    const updateCompanyUser = (companyUser) => {
        setCompanyUserToUpdate(companyUser);
        setShowUpdateMemberModal(true);
    }

    const numberOfFullAdmins = _.filter(members, (m) => !m.pending && m.role === "default_admin");
    return (
        <BaseContainer>
            <ContentContainer>
                <Section title={t('team.title')} actions={[{
                        variant: "btn btn-primary",
                        icon: "fa-plus",
                        label: t('team.invite'),
                        onClick: () => onInviteUser()
                    }]}>
                    <MapleTable>
                        <MapleTable.Content>
                            <thead>
                                <tr>
                                    <MapleTable.TH>{ t('team.team_member') }</MapleTable.TH>
                                    <MapleTable.TH>{ t('team.member_title')}</MapleTable.TH>
                                    <MapleTable.TH>{ t('team.role') }</MapleTable.TH>
                                    <MapleTable.TH>{ t('team.status') }</MapleTable.TH>
                                    <MapleTable.TH></MapleTable.TH>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {
                                _.map(members, (row, i) =>
                                     <tr key={i} className={classnames(row.pending && "de-highlighted")}>
                                        <td>
                                            <Columns.CompanyUserName companyUser={row} />
                                        </td>
                                         <td>
                                             {row.title}
                                         </td>
                                        <td>
                                            { getRoleDescription(row) }
                                        </td>
                                        <td>
                                        {
                                            row.pending ?
                                                <Label.Warning>{t('common.pending')}</Label.Warning>
                                            : <Label.Success>{t('common.active')}</Label.Success>
                                        }
                                        </td>
                                        <td className="text-end">
                                            <div className="flex flex-row justify-content-end">
                                                <Button
                                                    size="sm" variant="text-primary" className="whitespace-nowrap"
                                                    onClick={() => updateCompanyUser(row)}>Edit
                                                </Button>
                                                {
                                                    !row.pending && numberOfFullAdmins.length > 1 &&
                                                        <ConfirmationButton variant="text-primary" size="sm"
                                                            onConfirm={() => revokeAccess(row)}>
                                                            {t('team.revoke_access')}</ConfirmationButton>
                                                }
                                            </div>
                                            {
                                                row.pending &&
                                                    <div className="flex flex-row justify-content-end">
                                                        <Button size="sm" variant="text-primary" className="whitespace-nowrap" onClick={() => resendInvite(row)}>Re-send</Button>
                                                        <span className="hidden sm:inline">·</span>
                                                        <ConfirmationButton size="sm" variant="text-primary"
                                                            onConfirm={() => revokeInvite(row)}>
                                                            {t('team.revoke_invite')}</ConfirmationButton>
                                                    </div>
                                            }
                                        </td>
                                     </tr>
                                )
                            }
                            </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                </Section>
            </ContentContainer>
            <InviteCompanyUserModal show={showInviteMemberModal} onClose={onModalClose} />
            <UpdateCompanyUserModal
                show={showUpdateMemberModal} companyUser={companyUserToUpdate} onClose={onModalClose} numberOfFullAdmins={numberOfFullAdmins.length} />
        </BaseContainer>
    );
}

export default Team;
