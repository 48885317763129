import '../App.scss';
import '../css/invoice.scss';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import { serverPost, serverPatch } from '../helpers/server';
import {
    currencyFormatFromPrice,
    BaseContext,
    downloadBlob,
    UserContext
} from '../helpers/common';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';
import Notification from './Notification';
import InvoicePaymentModal from './modals/InvoicePaymentModal';
import ContentContainer from './ContentContainer';
import CopyableComponent from './CopyableComponent';
import InvoiceDetailsComponent from './InvoiceDetailsComponent';
import SingleInvoiceEmailModal from './modals/SingleInvoiceEmailModal';
import MarkInvoiceAsPaidModal from './modals/MarkInvoiceAsPaidModal';
import SimpleModal from './modals/SimpleModal';
import Label from './Label';
import {Button, Alert } from 'react-bootstrap';
import moment from 'moment';
import classnames from 'classnames';
import EditInvoiceModal from "./modals/EditInvoiceModal";
import DropdownMenu from "./DropdownMenu";
import { WrenchScrewdriverIcon, ShareIcon } from '@heroicons/react/20/solid'
import InvoiceTaxModal from "./modals/InvoiceTaxModal";
import {getShareLinkUrl, renderStatusLabel} from "../helpers/invoices";
import IntegrationReferenceEntitySummaryModal from "./modals/IntegrationReferenceEntitySummary";
const _ = require('lodash');

function InvoiceComponent(props) {
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    let redirectStatus = searchParams.get('redirect_status');
    let setupIntent = searchParams.get('setup_intent');
    const { company, getApiUrl, getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
    const { isSuperUser, isMapleUser, userInfo } = useContext(UserContext);

    const { t } = useTranslation('common');
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [settings, setSettings] = useState({});
    const [showInvoiceEmailModal, setShowInvoiceEmailModal] = useState(false);
    const [showInvoicePaidModal, setShowInvoicePaidModal] = useState(false);
    const [showInvoicePaymentModal, setShowInvoicePaymentModal] = useState(false);
    const [showShareLinkModal, setShowShareLinkModal] = useState(false);
    const [showVoidModal, setShowVoidModal] = useState(false);
    const [showFinalizeModal, setShowFinalizeModal] = useState(false);
    const [showEditInvoiceModal, setShowEditInvoiceModal] = useState(false);
    const [showInvoiceTaxModal, setShowInvoiceTaxModal] = useState(false);
    const [showReferenceSummaryModal, setShowReferenceSummaryModal] = useState(false);
    const [hideFooter, setHideFooter] = useState(false);
    const [canTriggerCampaign, setCanTriggerCampaign] = useState(false);

    const [showPaymentOptions, setShowPaymentOptions] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [isOverdue, setIsOverdue] = useState(false);
    const [isImportedInvoice, setIsImportedInvoice] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const hasInvoicesWritePermission = hasAccess("invoices", userInfo, "write");

    useEffect(() => {
        setInvoiceDetails(props.invoice);
        setIsImportedInvoice(!_.isNil(props.invoice) && !_.isNil(props.invoice.imported_from));
    }, [props.invoice, props.readOnly])

    useEffect(() => {
        setSettings(props.settings);
    }, [props.settings])

    useEffect(() => {
        if (!_.isNil(props.hideFooter)) {
            setHideFooter(props.hideFooter);
        } else {
            setHideFooter(false)
        }
    }, [props.hideFooter])

    useEffect(() => {
        if (_.isNil(redirectStatus)) {
            return;
        }
        setTimeout(() => {
            let urlParser = new URL(window.location.href);
            urlParser.searchParams.delete('payment_intent');
            urlParser.searchParams.delete('payment_intent_client_secret');
            urlParser.searchParams.delete('redirect_status');
            navigate(urlParser.pathname + urlParser.search, { replace: true });
            window.location.reload();
        }, 1000);
    }, [redirectStatus])

    useEffect(() => {
        if (_.isNil(setupIntent)) {
            return;
        }
        processPayment(setupIntent);
    }, [setupIntent])

    const onError = async (res) => {
        const errorMessage = await res.json();
        setErrorMessage(errorMessage)
    };

    const processPayment = async (setupIntentId) => {
        const paymentData = {
            payment_data: {
                stripe: {
                    setup_intent_id: setupIntentId
                }
            }
        }
        serverPost(getApiUrl(`/invoices/${props.invoice.uuid}/pay`), paymentData, {}, onError).then(res => {
            if (res) {
                if (props.onUpdate) {
                    props.onUpdate();
                }
            }

            setTimeout(() => {
                let urlParser = new URL(window.location.href);
                urlParser.searchParams.delete('setup_intent');
                urlParser.searchParams.delete('setup_intent_client_secret');
                navigate(urlParser.pathname + urlParser.search, { replace: true });
                window.location.reload();
            }, 1000);
        })
    }

    useEffect(() => {
        if (invoiceDetails.status === "VOID") {
            setIsPaid(false);
            setIsOverdue(false);
            setShowPaymentOptions(false);
            return;
        }

        const _isPaymentProviderSetup = !_.isNil(settings.payment_config);
        const _isNotReadOnly = !props.readOnly;
        const _hasDues = !_.isEmpty(invoiceDetails.due) && invoiceDetails.due.value_in_cents > 0;
        const _isPaid = !_.isEmpty(invoiceDetails.due) && invoiceDetails.due.value_in_cents === 0;
        const _isDueDateInPast = invoiceDetails.due_date && moment(invoiceDetails.due_date).isBefore(moment());
        setShowPaymentOptions(_isPaymentProviderSetup && _isNotReadOnly && _hasDues && !isImportedInvoice);

        setIsPaid(_isPaid);
        setIsOverdue(_hasDues && _isDueDateInPast);
    }, [settings, invoiceDetails, props.readOnly, isImportedInvoice])

    const onModalClose = (didUpdate) => {
        setShowInvoicePaymentModal(false);
        setShowShareLinkModal(false);
        setShowInvoicePaidModal(false);
        setShowVoidModal(false);
        setShowFinalizeModal(false);
        setShowEditInvoiceModal(false);
        setShowReferenceSummaryModal(false);
        if (didUpdate) {
            if (props.onUpdate) {
                props.onUpdate();
            }
        }
    }

    const onPay = () => {
        setErrorMessage(null)
        setShowInvoicePaymentModal(true);
    }

    const onActionSelected = (type) => {
        if (type === "edit") {
            setShowEditInvoiceModal(true);
        } else if (type === "email") {
            setShowInvoiceEmailModal(true);
        } else if (type === "link") {
            setShowShareLinkModal(true);
        } else if (type === "pdf") {
            serverPost(getApiUrl(`/invoices/${invoiceDetails.uuid}/pdf`), {}, { noJson: true }).then((res) => {
                downloadBlob(res, `Invoice-${invoiceDetails.number}`);
            })
        } else if (type === "mark_paid") {
            setShowInvoicePaidModal(true);
        } else if (type === "mark_void") {
            setShowVoidModal(true);
        } else if (type === "trigger_campaign") {
            const data = {
                type: "invoice_reminder",
                invoice_uuid: invoiceDetails.uuid,
                customer_id: invoiceDetails.customer_id
            }
            serverPost(getApiUrl("/campaigns/trigger"), data).then((res) => {
                if (res) {
                    Notification.Success("Successfully started reminder schedule")
                    if (props.onUpdate) {
                        props.onUpdate();
                    }
                } else {
                    Notification.Danger("Could not start reminder schedule")
                }
            })
        } else if (type === "references") {
            setShowReferenceSummaryModal(true);
        } else if (type === "finalize") {
            setShowFinalizeModal(true);
        }
    }

    const canRetryPayment = props.isAdmin && !isImportedInvoice && invoiceDetails.auto_charges && invoiceDetails.status === "PENDING" && hasInvoicesWritePermission;

    const canEdit = props.isAdmin && !isImportedInvoice && _.includes(["PENDING", "DRAFT"], invoiceDetails.status);
    const canAddPayment = props.isAdmin && !isImportedInvoice && !isPaid && _.includes(["PENDING"], invoiceDetails.status);
    const canVoidInvoice = props.isAdmin && !isImportedInvoice && !isPaid && _.includes(["PENDING"], invoiceDetails.status);
    const canFinalizeInvoice = props.isAdmin && !isImportedInvoice && _.includes(["DRAFT"], invoiceDetails.status);
    let canIssueCreditNote = props.isAdmin && !isImportedInvoice && (isMapleUser || isSuperUser) && !_.includes(["DRAFT", "VOID"], invoiceDetails.status);
    if (canIssueCreditNote && !_.isEmpty(invoiceDetails)) {
        // Check if there is any creditable amount left
        let totalCreditedValueInCents = 0
        _.each(invoiceDetails.pre_payment_credit_notes, n => {
            totalCreditedValueInCents += n.total.value_in_cents
        })
        _.each(invoiceDetails.post_payment_credit_notes, n => {
            totalCreditedValueInCents += n.total.value_in_cents
        })
        canIssueCreditNote = (invoiceDetails.total.value_in_cents > totalCreditedValueInCents);
    }
    const hasActions = canAddPayment || canVoidInvoice || canFinalizeInvoice || isSuperUser;

    useEffect(() => {
        const isPending = invoiceDetails.status === "PENDING";
        setCanTriggerCampaign(isPending && getCanTriggerCampaign());
    }, [invoiceDetails, settings]);

    const getCanTriggerCampaign = () => {
        const areEnabled =  settings.invoice_email_config && settings.invoice_email_config.send_customer_emails &&
            settings.non_auto_charge_invoice_reminder_enabled
        const noActiveCampaign = invoiceDetails.campaigns &&
            (invoiceDetails.campaigns.length === 0 ||
                !invoiceDetails.campaigns.some(c => c.status === "ACTIVE"));

        return areEnabled && noActiveCampaign;
    }

    const showDivider = canEdit || canIssueCreditNote || canAddPayment;
    const actionOptions = hasActions ? [
        canEdit && { id: "edit", label: "Edit" },
        canIssueCreditNote && { id: "credit_note", label: "Issue Credit Note", link: getCompanySpecificUrl(`/invoice/${invoiceDetails.uuid}/credit_note`) },
        canAddPayment && { id: "mark_paid", label: "Mark as Paid" },
        showDivider && { divider: true },
        canVoidInvoice && { id: "mark_void", label: "Mark as Void" },
        canFinalizeInvoice && { id: "finalize", label: "Finalize Invoice" },
        canTriggerCampaign && { id: "trigger_campaign", label: "Start Reminder Schedule" },
        isSuperUser && { id: "references", label: "References" },
    ]: [];

    const shareOptions = [
        { id: "link", label: "Share via Link" },
        { id: "email", label: "Share via Email" },
        { id: "pdf", label: "Download PDF" },
    ];

    const onRetryPayment = () => {
        serverPost(getApiUrl(`/invoices/${invoiceDetails.uuid}/charge`), {}).then((res) => {
            if (res && res.success) {
                Notification.Success("Payment attempt initiated.");
                if (props.onUpdate) {
                    props.onUpdate();
                }
            }
        })
    }

    const voidInvoice = () => {
        const data = {
            'status': "VOID"
        }
        serverPatch(getApiUrl(`/invoices/${invoiceDetails.uuid}`), data).then((res) => {
            if (res) {
                Notification.Success("Successfully voided invoice.");
                if (props.onUpdate) {
                    props.onUpdate();
                }
            }
        });
    }

    const finalizeInvoice = () => {
        serverPost(getApiUrl(`/invoices/${invoiceDetails.uuid}/finalize`), {}).then((res) => {
            if (res) {
                Notification.Success("Successfully finalized invoice.");
                if (props.onUpdate) {
                    props.onUpdate();
                }
            }
        });
    }

    const recomputeTaxes = () => {
        const data = {
            'recompute_taxes': true
        }
        serverPatch(getApiUrl(`/invoices/${invoiceDetails.uuid}`), data).then((res) => {
            if (res) {
                Notification.Success("Successfully recomputed taxes.");
                if (props.onUpdate) {
                    props.onUpdate();
                }
            }
        });
    }

    const renderStatus = () => {
        if (props.readOnly) {
            return;
        }

        if (invoiceDetails.status === "PENDING_PROCESSING") {
            return <Label.Info>Void</Label.Info>
        } else if (isPaid) {
            return <Label.Success>Paid&nbsp;<i className="fa fa-check"/></Label.Success>
        } else if (isOverdue) {
            return <Label.Danger>Overdue</Label.Danger>
        } else if (invoiceDetails.status === "VOID") {
            return <Label.Info>Void</Label.Info>
        } else if (invoiceDetails.status === "PENDING") {
            return <Label.Info>Pending</Label.Info>
        }
    }

    const renderReminders = () => {
        if (!isSuperUser) {
            return null;
        }
        if (invoiceDetails.campaigns && invoiceDetails.campaigns.length > 0) {
            const campaign = invoiceDetails.campaigns[0];
            if (campaign.status !== "ACTIVE") {
                return null;
            }
            const doneSteps = campaign.current_step-1;
            let nextStepDescription = null;
            if (campaign.next_action_in) {
                if (campaign.next_action_type === "payment_retry") {
                    nextStepDescription = `Payment retry scheduled for ${moment(campaign.next_action_in).format("MMM D, YYYY h:mm:ssa")}`
                } else if (campaign.next_action_type === "notification") {
                    nextStepDescription = `Reminder email scheduled for ${moment(campaign.next_action_in).format("MMM D, YYYY h:mm:ssa")}`
                } else if (campaign.next_action_type === "cancel_subscription") {
                    nextStepDescription = `Subscription to be cancelled on ${moment(campaign.next_action_in).format("MMM D, YYYY h:mm:ssa")}`
                } else if (campaign.next_action_type === "unpaid_subscription") {
                    nextStepDescription = `Subscription to be marked unpaid on ${moment(campaign.next_action_in).format("MMM D, YYYY h:mm:ssa")}`
                }
            }
            return (
                <div className="w-full px-2 text-end text-gray-500 italic">
                    {
                        doneSteps > 0 &&
                            <>
                                <span>{ doneSteps } reminders sent. </span>
                                {
                                    nextStepDescription &&
                                        <span>{ nextStepDescription }</span>
                                }
                            </>
                    }
                    {
                        doneSteps === 0 &&
                            <>
                                {
                                    nextStepDescription &&
                                    <span>{ nextStepDescription }</span>
                                }
                            </>
                    }
                </div>
            )
        } else {
            return null;
        }
    }

    return (
        <>
            <Loader loading={_.isEmpty(invoiceDetails)}>
            {
                () =>
                    <ContentContainer>
                        <div className="d-flex justify-content-center">
                            <div className={classnames("invoice-wrapper", props.wrapperClassName)}>
                                {
                                    redirectStatus &&
                                        <div className="invoice-wrapper-header">
                                        {
                                            redirectStatus === "succeeded" &&
                                                <div className="flex-grow-1 text-center">
                                                    <Alert variant="success">Payment has been successfully processed.</Alert>
                                                </div>
                                        }
                                        {
                                            redirectStatus === "failed" &&
                                                <div className="flex-grow-1 text-center">
                                                    <Alert variant="danger">Payment processing has failed. Please try again later.</Alert>
                                                </div>
                                        }
                                        </div>
                                }
                                {
                                    errorMessage &&
                                        <div className="invoice-wrapper-header">
                                            <span className="form-error-message">{ errorMessage }</span>
                                        </div>
                                }
                                <div className="invoice-wrapper-header">
                                    <div className="flex-grow-0 d-flex flex-row gap-3 align-items-center" >
                                    {
                                        showPaymentOptions && !props.isAdmin &&
                                            <Button variant="primary" onClick={onPay}>Pay Now</Button>
                                    }
                                    {
                                        canRetryPayment &&
                                            <Button variant="primary" onClick={() => onRetryPayment()}>Retry Payment</Button>
                                    }
                                    { renderStatusLabel(invoiceDetails, getCompanySpecificUrl) }
                                    {
                                        !props.readOnly && isPaid && invoiceDetails.total.value_in_cents > 0 &&
                                            <h4>{ currencyFormatFromPrice(invoiceDetails.total) }</h4>
                                    }
                                    {
                                        showPaymentOptions &&
                                            <h4>{ currencyFormatFromPrice(invoiceDetails.due) }</h4>
                                    }
                                    </div>
                                    <div className="flex-grow-1"/>
                                    <div className="flex-grow-0 d-flex gap-2">
                                        {
                                            !props.readOnly && !isImportedInvoice && !_.isEmpty(actionOptions) && hasInvoicesWritePermission &&
                                                <DropdownMenu className="p-2" items={actionOptions} onClick={onActionSelected}>
                                                    <div className="flex flex-row gap-1 items-center">
                                                        <WrenchScrewdriverIcon className="h-4 w-4"/>
                                                        <span>Actions</span>
                                                    </div>
                                                </DropdownMenu>
                                        }
                                        {
                                            !props.readOnly && !isImportedInvoice &&
                                                <DropdownMenu className="p-2" items={shareOptions} onClick={onActionSelected}>
                                                    <div className="flex flex-row gap-1 items-center">
                                                        <ShareIcon className="h-4 w-4"/>
                                                        <span>Share</span>
                                                    </div>
                                                </DropdownMenu>
                                        }
                                    </div>
                                </div>
                                { renderReminders() }
                                <InvoiceDetailsComponent
                                    {...props}
                                    recomputeTaxes={recomputeTaxes}
                                    showTaxDetailsModal={() => setShowInvoiceTaxModal(true)}
                                    showTaxAsEstimate={props.showTaxAsEstimate}
                                    onChangeIncludeTaxes={props.onChangeIncludeTaxes} />
                                {
                                    !hideFooter &&
                                    <div className="invoice-wrapper-footer">
                                        <div className="flex-grow-1"/>
                                        <div className="flex-grow-0 d-flex flex-row gap-3 align-items-center" >
                                            <span>Powered by Maple</span>
                                            <a href="https://www.maplebilling.com/terms" target="_blank">Terms</a>
                                            <a href="https://www.maplebilling.com/privacy" target="_blank">Privacy</a>
                                        </div>
                                        <div className="flex-grow-1"/>
                                    </div>
                                }
                            </div>
                        </div>
                    </ContentContainer>
            }
            </Loader>
            <SingleInvoiceEmailModal show={showInvoiceEmailModal} onClose={setShowInvoiceEmailModal} invoice={invoiceDetails} />
            {
                props.isAdmin && !isPaid && canAddPayment &&
                    <MarkInvoiceAsPaidModal show={showInvoicePaidModal} onClose={onModalClose} invoice={invoiceDetails} />
            }
            <InvoicePaymentModal
                show={showInvoicePaymentModal} onClose={onModalClose} invoice={invoiceDetails}
                paymentConfig={invoiceDetails.payment_config} settings={settings}
            />
            <SimpleModal show={showShareLinkModal} onClose={onModalClose} title="Share Invoice" buttonTitle="Done"
                body={
                    <>
                    <p className="body1">Please feel free to share this link with any relevant party.</p>
                    <CopyableComponent
                        className="mt-2"
                        value={<a className="">{getShareLinkUrl(invoiceDetails, getCompanySpecificUrl)}</a>}
                        copyableValue={getShareLinkUrl(invoiceDetails, getCompanySpecificUrl)}
                    />
                    </>
                } />
            <SimpleModal
                show={showVoidModal}
                onClose={onModalClose}
                title="Confirm"
                buttonTitle="Confirm"
                onConfirm={voidInvoice}
                body={
                    <>
                        <p>Are you sure you want to void the invoice?</p>
                        <span className="caption italic">Note: This action can not be reversed once completed.</span>
                    </>
                }
            />
            <SimpleModal
                show={showFinalizeModal}
                onClose={onModalClose}
                title="Confirm"
                buttonTitle="Confirm"
                onConfirm={finalizeInvoice}
                body={
                    <>
                        <p>Are you sure you want to finalize the invoice?</p>
                        <span className="caption italic">Note: This action can not be reversed once completed.</span>
                    </>
                }
            />
            <EditInvoiceModal
                show={showEditInvoiceModal}
                onClose={onModalClose}
                invoice={invoiceDetails}
            />
            <InvoiceTaxModal
                show={showInvoiceTaxModal} onClose={setShowInvoiceTaxModal} invoice={props.invoice}
                recomputeTaxes={recomputeTaxes}
            />
            <IntegrationReferenceEntitySummaryModal
                show={showReferenceSummaryModal} onClose={onModalClose} entityKey={"INVOICE"}
                referenceID={invoiceDetails.id}
            />
        </>
    );
}

export default InvoiceComponent;
