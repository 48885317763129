import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { BaseContext } from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import ContentBox from '../../components/ContentBox';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BaseProposalCreateOrUpdateForm from "../../components/BaseProposalCreateOrUpdateForm";
import {serverFetch} from "../../helpers/server";
import Breadcrumb from "../../components/Breadcrumb";
const _ = require('lodash');

function PaymentLinkCreateOrUpdate() {
    const { t } = useTranslation('common');
    const { uuid } = useParams();

    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [paymentLink, setPaymentLink] = useState({});

    useEffect(() => {
        setPageTitle(`Payment Link - Create`);
    }, []);

    useEffect(() => {
        if (!_.isNil(uuid)) {
            serverFetch(getApiUrl(`/proposals/${uuid}`)).then((res) => {
                setPaymentLink(res);
            })
        }
    }, [uuid])

    const isEditing = !_.isNil(uuid);
    const breadcrumbItems = [
        { name: 'Payment Links', href: getCompanySpecificUrl(`/payment_links`), current: false },
        { name: isEditing ? paymentLink.id: "New", current: true }
    ]
    return (
        <BaseContainer>
            <ContentContainer>
                <Breadcrumb items={breadcrumbItems}/>
                <ContentBox>
                    <ContentBox.Body>
                        <BaseProposalCreateOrUpdateForm type={"CHECKOUT"} />
                    </ContentBox.Body>
                </ContentBox>
            </ContentContainer>
        </BaseContainer>
    );
}

export default PaymentLinkCreateOrUpdate;
