import '../../App.scss';
import React, {useEffect, useState, useContext, useMemo} from 'react';
import { useParams } from 'react-router-dom';
import {serverDelete, serverFetch, serverPatch, serverPost} from '../../helpers/server';
import {
    BaseContext,
    UserContext,
    currencyFormatFromPrice,
    getNameForProviderType,
    getInvoicePaymentTermDescription,
    getCustomerNameOrEmail,
    formatTerm, getPaymentMethodDescription,
} from '../../helpers/common';
import {Alert, Button, Col, Row} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import InvoiceListSection from '../../components/InvoiceListSection';
import KeyValueDisplay2 from '../../components/KeyValueDisplay2';
import KeyValueDisplay from '../../components/KeyValueDisplay';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import BundlePricingTable from '../../components/BundlePricingTable';
import Loader from '../../components/Loader';
import PausePaymentsModal from '../../components/modals/PausePaymentsModal';
import SimpleModal from '../../components/modals/SimpleModal';
import UpdateSubscriptionModal from '../../components/modals/UpdateSubscriptionModal';
import CancelSubscriptionModal from '../../components/modals/CancelSubscriptionModal';
import UpdateSubscriptionAutoChargeModal from '../../components/modals/UpdateSubscriptionAutoChargeModal';
import UpdateSubscriptionPaymentMethodModal from '../../components/modals/UpdateSubscriptionPaymentMethodModal';
import Notification from '../../components/Notification';
import Label from '../../components/Label';
import moment from 'moment';
import UpdateTrialEndDateModal from "../../components/modals/UpdateTrialEndDateModal";
import DiscountListSection from "../../components/DiscountListSection";
import ConfirmationButton from "../../components/ConfirmationButton";
import SelectEntitlements from "../../components/SelectEntitlements";
import {WrenchScrewdriverIcon} from "@heroicons/react/20/solid";
import DropdownMenu from "../../components/DropdownMenu";
import DeleteButton from "../../components/DeleteButton";
import Link from "../../components/Link";
import ErrorComponent from "../../components/ErrorComponent";
import SubscriptionHistoryModal from "../../components/modals/SubscriptionHistoryModal";
import {getDescriptionForSubscriptionCancelReason} from "../../helpers/subscriptions";
import Tags from "../../components/Tags";
import SubscriptionUsage from "../../components/SubscriptionUsage";
import ContentBox from "../../components/ContentBox";
import UpdateSubscriptionPaymentInstructionsModal from '../../components/modals/UpdateSubscriptionPaymentInstructionsModal';
import CreditTable from "../../components/CreditTable";
const _ = require('lodash');

function Subscription(props) {
    const { t } = useTranslation('common');
    const { subscriptionId } = useParams();
    const { userInfo } = useContext(UserContext);
    const { getApiUrl, getCompanySpecificUrl, company, setPageTitle, hasAccess } = useContext(BaseContext);
    const { isMapleUser } = useContext(UserContext);
    const [showUpdateSubscriptionModal, setShowUpdateSubscriptionModal] = useState(false);
    const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false);
    const [showDeleteCancellationSubscriptionModal, setShowDeleteCancellationSubscriptionModal] = useState(false);
    const [showPausePaymentsModal, setShowPausePaymentsModal] = useState(false);
    const [showResumePaymentsModal, setShowResumePaymentsModal] = useState(false);
    const [showUpdateTrialEndModal, setShowUpdateTrialEndModal] = useState(false);
    const [showUpdateSubscriptionAutoChargeModal, setShowUpdateSubscriptionAutoChargeModal] = useState(false);
    const [showUpdateSubscriptionPaymentMethodModal, setShowUpdateSubscriptionPaymentMethodModal] = useState(false);
    const [showUpdateSubscriptionPaymentInstructionsModal, setShowUpdateSubscriptionPaymentInstructionsModal] = useState(false);
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [loadingSubscription, setLoadingSubscription] = useState(true);
    const [subscription, setSubscription] = useState({});
    const [paymentMethods, setPaymentMethods] = useState(null);
    const [discounts, setDiscounts] = useState([]);
    const [nextInvoice, setNextInvoice] = useState(null);
    const [details, setDetails] = useState({});
    const [isEditingMetadata, setIsEditingMetadata] = useState(false);
    const [isEditingEntitlements, setIsEditingEntitlements] = useState(false);
    const [isEditingInvoiceDisplayFields, setIsEditingInvoiceDisplayFields] = useState(false);
    const [hasUsageMetricPricings, setHasUsageMetricPricings] = useState(false);
    const [subscriptionCredits, setSubscriptionCredits] = useState([]);
    const extraQueryFilters = useMemo(() => {
        return { subscription_id: subscriptionId }
    }, [subscriptionId]);

    const hasWritePermission = hasAccess("subscriptions", userInfo, "write") && subscription.customer && !subscription.customer.managed_externally;
    const hasInvoicesPermission = hasAccess("invoices", userInfo, "read");

    useEffect(() => {
        setPageTitle(`Subscription`);
    }, []);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/subscriptions/${subscriptionId}`), { skipCache }).then((res) => {
            if (res) {
                res.isCancelled = moment().isAfter(res.end_date);
                setSubscription(res);

                let pmps = [];
                _.each(res.product_pricings, pp => {
                    _.each(pp.product_metric_pricings, pmp => {
                        pmps.push(pmp);
                    })
                })
                const customMetricPricings = _.filter(pmps, (pmp) => {
                    return pmp.metric.type === "CUSTOM_METRIC" &&
                        !_.startsWith(pmp.metric.metering_rule.aggregator, "OBJ_") &&
                        !_.includes(["CUSTOMER_LAST_EVER", "LAST_EVER_SUM"], pmp.metric.metering_rule.aggregator)
                })
                setHasUsageMetricPricings(!_.isEmpty(customMetricPricings));
            }
            setLoadingSubscription(false);
        });

        serverPost(getApiUrl(`/discounts/find`), { subscription_id: subscriptionId }).then((res) => {
            if (res) {
                setDiscounts(res);
            }
        });

        fetchCredits();
    }

    const fetchCredits = () => {
        const creditsParams = {
            pagination: {
                from_key: null,
                limit: 100,
            },
            query: {
                subscription_id: subscriptionId,
            }
        }
        serverPost(getApiUrl(`/credits/find`), creditsParams).then(res => {
            if (res) {
                setSubscriptionCredits(res.results);
            }
        })
    }

    const onRevokeCredit = (creditID) => {
        serverDelete(getApiUrl(`/credits/${creditID}`)).then(() => {
            fetchCredits();
        });
    };

    const fetchNextInvoice = () => {
        if (!hasInvoicesPermission) {
            setNextInvoice(null);
            return;
        }
        if (_.isNil(subscription) || _.isEmpty(subscription) || _.isNil(subscription.next_invoice_date) || subscription.isCancelled) {
            setNextInvoice(null);
        } else {
            const data = {
                subscription_id: subscriptionId,
                customer_id: subscription.customer.id,
                currency: subscription.bundle_pricing.currency
            }
            serverPost(getApiUrl(`/invoices/next`), data).then((res) => {
                if (res) {
                    setNextInvoice(res);
                }
            });
        }
    }

    const fetchPaymentMethods = () => {
        if (!_.isEmpty(subscription) && subscription.payment_method_id) {
            serverFetch(getApiUrl(`/customers/${subscription.customer_id}/payment_methods`), {}).then((res) => {
                if (res) {
                    setPaymentMethods(res.methods);
                }
            });
        }
    }

    const editEntitlements = () => {
        setIsEditingEntitlements(true);
    }

    const saveEntitlements = (newEntitlements) => {
        const processedData = {
            override_entitlements: newEntitlements
        };

        serverPatch(getApiUrl(`/subscriptions/${subscriptionId}`), processedData).then((res) => {
            if (res) {
                fetchData(true);
            }
            setIsEditingEntitlements(false);
        });
    }

    useEffect(() => {
        fetchNextInvoice();
        fetchPaymentMethods();
    }, [subscription])

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (_.isEmpty(subscription)) {
            return;
        }
        let endDate = null;
        if (subscription.end_date) {
            if (moment().isAfter(subscription.end_date)) {
                endDate = moment(subscription.end_date).format("DD MMM, YYYY h:mm:ssa");
            } else if (!_.isNil(subscription.change_config)) {
                endDate = null;
            } else {
                endDate = `${moment(subscription.end_date).format("DD MMM, YYYY h:mm:ssa")}`;
            }
        }
        const paymentTermDescription = getInvoicePaymentTermDescription(subscription.invoice_due_date_from_creation);
        let data = {
            'Customer': <Link href={getCompanySpecificUrl(`/customer/${subscription.customer.id}`)}>{getCustomerNameOrEmail(subscription.customer)}</Link>,
        }
        if (subscription.parent_billed) {
            data['Bill To'] = <Link href={getCompanySpecificUrl(`/customer/${subscription.customer.parent_customer_id}`)}>{getCustomerNameOrEmail(subscription.customer.parent_customer)}</Link>
        }
        const selectedPaymentMethod = _.find(paymentMethods, (m) => m.id === subscription.payment_method_id);
        data = _.merge(data, {
            'ID': subscription.id,
            'Next Invoice': nextInvoice && <Link href={getCompanySpecificUrl(`/subscription/${subscription.id}/next_invoice`)}>{ currencyFormatFromPrice(nextInvoice.due) } on { moment(nextInvoice.invoice_date).format("MMM D, YYYY h:mm:ss a") }</Link>,
            'Start Date': moment(subscription.start_date).format("DD MMM, YYYY h:mm:ssa"),
            'End Date': endDate,
            'Renewal Date': subscription.renewal_date && moment(subscription.renewal_date).format("DD MMM, YYYY h:mm:ssa"),
            'Billing Method/Payment Terms':
                <div className="d-flex flex-row gap-2 align-items-center">
                    <span key={0}>
                        { subscription.auto_charges ? "Automatic": "Manual" }
                    </span>
                    {
                        !_.isNil(paymentTermDescription) &&
                            <span key={1} className="text-gray-400">({ paymentTermDescription })</span>
                    }
                    <span key={2} className="grow"/>
                    {
                        hasWritePermission &&
                        <Button key={3} onClick={() => setShowUpdateSubscriptionAutoChargeModal(true)} variant="text" size="sm"><i className="fa fa-edit"/></Button>
                    }
                </div>,
            'Payment Method':
                <div className="d-flex flex-row gap-2 align-items-center">
                    <div key={"a1"}>
                        {
                            selectedPaymentMethod ?
                                getPaymentMethodDescription(t, selectedPaymentMethod)
                            : <span key={"a4"} className="text-gray-400">Use default customer payment method</span>
                        }
                    </div>
                    <span key={"a2"} className="grow"/>
                    {
                        hasWritePermission &&
                        <Button key={"a3"} onClick={() => setShowUpdateSubscriptionPaymentMethodModal(true)} variant="text" size="sm"><i className="fa fa-edit"/></Button>
                    }
                </div>,
            'MRR': currencyFormatFromPrice(subscription.mrr),
            'Tags': <Tags tags={subscription.tags} onUpdate={updateTags} tagsUrl={getApiUrl(`/subscriptions/tags`)} isEditable={hasWritePermission} />
        });
        if (subscription.invoice_event_grace_period > 0) {
            data['Invoice Grace Period Until'] = subscription.next_invoice_date && moment(subscription.next_invoice_date).add(moment.duration(subscription.invoice_event_grace_period, 'seconds')).format("DD MMM, YYYY h:mm:ssa")
        }
        if (!_.isNil(subscription.imported_from)) {
            data[`${getNameForProviderType(subscription.imported_from)} Reference`] =
                <a href={`https://dashboard.stripe.com/subscriptions/${subscription.import_ref}`} target="_blank">{ subscription.import_ref }</a>;
        }
        if (subscription.trial) {
            delete data['End Date'];
            delete data['Renewal Date'];
            delete data['Billing Method'];
            data['Trial End'] = endDate;
        }
        if (!hasInvoicesPermission) {
            delete data['Next Invoice'];
        }
        if (subscription.minimum_spend && subscription.minimum_spend.value_in_cents > 0) {
            data['Minimum Spend'] = currencyFormatFromPrice(subscription.minimum_spend);
        }
        if (subscription.cancel_reason) {
            data['Cancellation Reason'] = getDescriptionForSubscriptionCancelReason(subscription.cancel_reason);
        }
        setDetails(data);
    }, [subscription, nextInvoice, getCompanySpecificUrl, paymentMethods]);

    const onModalClose = (didUpdate=false) => {
        setShowCancelSubscriptionModal(false);
        setShowDeleteCancellationSubscriptionModal(false);
        setShowUpdateSubscriptionModal(false);
        setShowPausePaymentsModal(false);
        setShowResumePaymentsModal(false);
        setShowUpdateTrialEndModal(false);
        setShowUpdateSubscriptionAutoChargeModal(false);
        setShowUpdateSubscriptionPaymentMethodModal(false);
        setShowHistoryModal(false);
        setShowUpdateSubscriptionPaymentInstructionsModal(false)
        fetchData(didUpdate);
    }

    const updateTags = async (data) => {
        const result = await serverPatch(getApiUrl(`/subscriptions/${subscriptionId}`), data);
        if (result) {
            fetchData(true);
        }
        return result;
    }

    const editMetadata = () => {
        setIsEditingMetadata(true);
    }

    const saveMetadata = (data) => {
        const processedData = {
            metadata: data
        };

        serverPatch(getApiUrl(`/subscriptions/${subscriptionId}`), processedData).then((res) => {
            if (res) {
                fetchData(true);
            }
            setIsEditingMetadata(false);
        });
    }

    const saveInvoiceDisplayFields = (data) => {
        const fieldsData = {
            invoice_additional_display_fields: _.map(data, (value, key) => { return { name: key, value: value} })
        };
        serverPatch(getApiUrl(`/subscriptions/${subscriptionId}`), fieldsData).then((res) => {
            if (res) {
                fetchData(true);
            }
            setIsEditingInvoiceDisplayFields(false);
        });
    }

    const onActionSelected = (type) => {
        if (type === "update") {
            setShowUpdateSubscriptionModal(true);
        } else if (type === "cancel") {
            setShowCancelSubscriptionModal(true);
        } else if (type === "pause") {
            setShowPausePaymentsModal(true);
        } else if (type === "resume") {
            setShowResumePaymentsModal(true);
        } else if (type === "delete_cancellation") {
            setShowDeleteCancellationSubscriptionModal(true);
        } else if (type === "update_trial_end") {
            setShowUpdateTrialEndModal(true);
        } else if (type === "update_payment_instructions") {
            setShowUpdateSubscriptionPaymentInstructionsModal(true);
        }
    }

    const onConfirmResumePayments = () => {
        serverPost(getApiUrl(`/subscriptions/${subscription.id}/resume`)).then((res) => {
            fetchData(true);
        })
    }

    const onConfirmDeleteScheduledCancellation = () => {
        serverPost(getApiUrl(`/subscriptions/${subscription.id}/undo_cancel`)).then((res) => {
            fetchData(true);
        })
    }

    const isActiveTrial = subscription.trial && moment().isBefore(subscription.change_date);
    const actionOptions = [
        { id: "update", label: "Change Subscription" },
        isActiveTrial && { id: "update_trial_end", label: "Update Trial End Date" },
        !isActiveTrial && { divider: true },
        !isActiveTrial && _.isNil(subscription.pause_config) && { id: "pause", label: "Pause Payments" },
        !isActiveTrial && !_.isNil(subscription.pause_config) && { id: "resume", label: "Resume Payments" },
        { id: "update_payment_instructions", label: "Update Payment Instructions" },
        _.isNil(subscription.cancel_config) && { id: "cancel", label: "Cancel Subscription", className: "error" },
        !_.isNil(subscription.cancel_config) && { id: "delete_cancellation", label: "Delete Scheduled Cancellation", className: "error" },
    ];

    const renderActions = () => {
        if (!hasWritePermission) {
            return [];
        }
        if (subscription.isCancelled) {
            return [];
        }
        return (
            <DropdownMenu className="p-2" items={actionOptions} onClick={onActionSelected}>
                <div className="flex flex-row gap-1 items-center">
                    <WrenchScrewdriverIcon className="h-4 w-4"/>
                    <span>Actions</span>
                </div>
            </DropdownMenu>
        )
    }

    const renderSubscriptionStatus = (subscription) => {
        let endDate = null;
        let statusDescription = null;
        if (subscription.status === "CANCELLED") {
            statusDescription = <Label.Info className="d-flex align-items-center">Cancelled</Label.Info>
        } else if (subscription.trial) {
            if (subscription.end_date) {
                statusDescription =
                    <div className="flex flex-row gap-1">
                        <Label.Neutral className="d-flex align-items-center">Trials till {moment(subscription.end_date).format("MMM D")}</Label.Neutral>
                        <Label.Info className="d-flex align-items-center">Cancels on {moment(subscription.end_date).format("MMM D, YYYY")}</Label.Info>
                    </div>
            } else {
                statusDescription = <Label.Neutral className="d-flex align-items-center">Trials till {moment(subscription.change_date).format("MMM D")}</Label.Neutral>
            }
        } else if (subscription.end_date) {
            if (!_.isNil(subscription.change_config)) {
                statusDescription = <Label.Warning className="d-flex align-items-center">Changes on {moment(subscription.end_date).format("MMM D, YYYY")}</Label.Warning>
            } else {
                statusDescription = <Label.Info className="d-flex align-items-center">Cancels on {moment(subscription.end_date).format("MMM D, YYYY")}</Label.Info>
            }
        } else if (subscription.status === "UNPAID") {
            statusDescription = <Label.Danger className="d-flex align-items-center">Unpaid</Label.Danger>
        } else if (subscription.status === "ACTIVE") {
            statusDescription = <Label.Success className="d-flex align-items-center">Active</Label.Success>
        } else if (subscription.status === "PENDING_PAYMENT") {
            statusDescription = <Label.Warning className="d-flex align-items-center">Pending Payment Method</Label.Warning>
        } else {
            statusDescription = <Label.Info className="d-flex align-items-center">{ subscription.status }</Label.Info>
        }
        return statusDescription
    }

    const renderPauseStatus = (subscription) => {
        let endDate = null;
        if (!_.isNil(subscription.pause_config)) {
            let resumeDescription = "";
            if (!_.isNil(subscription.pause_config.resume_date)) {
                resumeDescription = ` till ${moment(subscription.pause_config.resume_date).format("MMM D hh:mma")}`
            }
            return <Label.Info>
                <i className="fa fa-pause"/>&nbsp;Paused Collections{ resumeDescription }
            </Label.Info>
        } else {
            return null
        }
    }

    const manageCustomer = () => {
        const data = {};
        serverPatch(getApiUrl(`/customers/${subscription.customer_id}/start_managing`), data).then((res) => {
            if (res) {
                fetchData(true);
            }
        });
    }

    const onCancelChange = () => {
        serverPost(getApiUrl(`/subscriptions/${subscription.id}/undo_change`), {}).then((res) => {
            if (res) {
                fetchData(true);
                Notification.Success("Scheduled Change Cancelled");
            }
        })
    }

    return (
        <BaseContainer>
            <Loader loading={loadingSubscription}>
            {
                _.isEmpty(subscription) ?
                    <ErrorComponent
                        title={"Subscription Not Found"}
                        description={"Oops, we can't find the subscription you are looking for."}
                        primaryAction={{ href:getCompanySpecificUrl("/subscriptions"), label:"Show all subscriptions" }}
                        hideSupport
                    />
                : <>
                    <ContentContainer title={
                            <div className="d-flex flex-row gap-3">
                                <span>Subscription for {subscription.customer && getCustomerNameOrEmail(subscription.customer)}</span>
                                <div className="d-flex align-items-center gap-3">
                                    { renderSubscriptionStatus(subscription) }
                                    { renderPauseStatus(subscription) }
                                </div>
                            </div>
                        }
                        customActions={ renderActions() }>
                        {
                            subscription.customer.managed_externally &&
                            <>
                                <Row className="align-items-center">
                                    <Col lg="9">
                                        <Alert variant="info">
                                            This subscription is currently managed in { getNameForProviderType(subscription.customer.imported_from) }.
                                            We will pull in any changes in { getNameForProviderType(subscription.customer.imported_from) } for this subscription.
                                            However, you will not be able to make any changes to this subscription here.
                                        </Alert>
                                    </Col>
                                    <Col lg="6">
                                        <div className="d-flex flex-column">
                                            <h5>Start Managing in Maple</h5>
                                            <span className="body1">Please note that once the customer is being managed in Maple, changes
                                            in { getNameForProviderType(subscription.customer.imported_from) } will not be copied over here.
                                        You can make changes in Maple going forward.
                                    </span>
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <ConfirmationButton
                                            body={<>
                                                <p>Are you sure you want to proceed?</p>
                                                <p className="mt-2 text-sm">Please note that once the customer is being managed in Maple,
                                                changes in { getNameForProviderType(subscription.customer.imported_from) } will not be
                                                copied over here. You can make changes in Maple going
                                                forward.</p>
                                            </>}
                                            onConfirm={() => manageCustomer()}
                                            buttonVariant="primary"
                                        >
                                            Start Managing
                                        </ConfirmationButton>
                                    </Col>
                                </Row>
                                <br/>
                            </>
                        }
                        {
                            !_.isNil(subscription.change_config) && _.isNil(subscription.cancel_config) && !subscription.isCancelled &&
                                <div className="mt-4">
                                    <div className="flex flex-row gap-2">
                                        <div className="grow">
                                            <span className="body2">Scheduled Change</span>
                                            <br/>
                                            <div>
                                                <span>A change has been scheduled for { moment(subscription.change_date).format("D MMM, YYYY h:mm:ssa") } (local timezone).</span>
                                            </div>
                                        </div>
                                        <div>
                                            <DeleteButton
                                                variant="text-danger"
                                                onDelete={onCancelChange}
                                                title="Confirm Cancel?"
                                                body="Are you sure you want to cancel the scheduled change?"
                                                buttonTitle="Cancel"
                                            >
                                                Cancel Change
                                            </DeleteButton>
                                        </div>
                                    </div>
                                    <BundlePricingTable
                                        showPricingLinks={true}
                                        bundlePricing={subscription.change_config.bundle_pricing}
                                        configItems={subscription.change_config.config_items}
                                        discounts={subscription.change_config.discounts}
                                        term={subscription.change_config.term}
                                        minimumSpend={subscription.change_config.minimum_spend}
                                        displayDiscountExpiration={true}
                                    />
                                </div>
                        }
                        <Section className="mt-4" title="Details" actions={[]}>
                            <KeyValueDisplay2 items={details} />
                        </Section>
                        <Section title="Entitlements" actions={!isEditingEntitlements && hasWritePermission && [
                            {variant: "text-primary", icon: "fa-edit", label: "Edit", onClick: editEntitlements}
                        ]} subtitle="These are features that are enabled on this subscription.">
                            <Row>
                                <Col lg="8">
                                    <SelectEntitlements
                                        entitlements={subscription.computed_entitlements}
                                        isEditing={isEditingEntitlements}
                                        onUpdateEntitlements={saveEntitlements}
                                        onCancelEditing={() => setIsEditingEntitlements(false)}
                                    />
                                </Col>
                            </Row>
                        </Section>
                        <Section title="Metadata" className="mt-4" actions={hasWritePermission && [
                            {variant: "text-primary", icon: "fa-edit", label: t('common.edit'), onClick: editMetadata}
                        ]} subtitle="These are fields that are stored on the subscription for internal reference.">
                            {
                                (!isEditingMetadata && (_.isNil(subscription.metadata) || _.isEmpty(subscription.metadata))) ?
                                        <p className="text-gray-500">No metadata</p>
                                    : <KeyValueDisplay items={subscription.metadata} isEditing={isEditingMetadata}
                                        onCancelEditing={() => setIsEditingMetadata(false)}
                                        onUpdateKeyValues={saveMetadata}/>
                            }
                        </Section>
                        <Section
                            title="Additional Information"
                            subtitle="These are additional fields that will be included on invoices for this subscription such as purchase order or reference numbers."
                            className="mt-4" actions={hasWritePermission && [
                            {variant: "text-primary", icon: "fa-edit", label: t('common.edit'), onClick: () => setIsEditingInvoiceDisplayFields(true)}
                        ]}>
                            {
                                (!isEditingInvoiceDisplayFields && (_.isNil(subscription.invoice_additional_display_fields) || _.isEmpty(subscription.invoice_additional_display_fields))) ?
                                    <p className="text-gray-500">No additional information</p>
                                    : <KeyValueDisplay items={_.mapValues(_.keyBy(subscription.invoice_additional_display_fields, 'name'), 'value')} isEditing={isEditingInvoiceDisplayFields}
                                                       onCancelEditing={() => setIsEditingInvoiceDisplayFields(false)}
                                                       onUpdateKeyValues={saveInvoiceDisplayFields}/>
                            }
                        </Section>
                        {
                            subscriptionCredits && !_.isEmpty(subscriptionCredits) &&
                                <Section title={"Subscription Credits"} className="mt-4">
                                    <CreditTable
                                        showTableActions={false}
                                        creditsData={{
                                            credits: subscriptionCredits,
                                            onRevoke: onRevokeCredit,
                                        }}
                                        filterData={{}}
                                        sortData={{}}
                                    />
                                </Section>
                        }
                        <Section
                            title="Product Prices" className="mt-4"
                            right={subscription.change_history.length > 1 &&
                                <Link onClick={() => setShowHistoryModal(true)}>View history</Link>
                            }
                        >
                            <BundlePricingTable
                                bundlePricing={subscription.bundle_pricing}
                                configItems={subscription.config_items}
                                discounts={discounts}
                                showUsage={true}
                                showPricingLinks={true}
                                subscription={subscription}
                                onUsageUpdated={() => fetchNextInvoice()}
                                hideDiscountAdditionalDetails={true}
                                displayDiscountExpiration={true}
                            />
                        </Section>
                        {
                            hasUsageMetricPricings &&
                                <Section title={"Usage"} className="mt-4">
                                    <ContentBox>
                                        <ContentBox.Body>
                                            <SubscriptionUsage subscriptions={[subscription]} className="mt-4" />
                                        </ContentBox.Body>
                                    </ContentBox>
                                </Section>
                        }
                        <DiscountListSection
                            customer={subscription.customer}
                            extraQueryFilters={extraQueryFilters}
                            allowCreation={false}
                        />
                        {
                            hasInvoicesPermission &&
                            <InvoiceListSection
                                includeCustomer={false}
                                showTableActions={false}
                                extraQueryFilters={extraQueryFilters}
                                hideTableIfEmpty={true}
                            />
                        }
                    </ContentContainer>
                    <CancelSubscriptionModal show={showCancelSubscriptionModal} onClose={onModalClose} subscription={subscription} />
                    <PausePaymentsModal show={showPausePaymentsModal} onClose={onModalClose} subscription={subscription} />
                    <SimpleModal show={showResumePaymentsModal} onClose={onModalClose} title="Resume Payments?"
                        buttonTitle="Resume" onConfirm={onConfirmResumePayments}
                        body={
                            <>
                            <span>Resuming payments will start processing any new invoices created from now. </span><br/><br/>
                            <span className="caption">Note: Any invoices
                            created while the payments were payments will not be collected automatically. You can change the
                            status on any draft invoices manually to collect on them.</span>
                            </>
                        }
                    />
                    <UpdateSubscriptionModal
                        show={showUpdateSubscriptionModal}
                        onClose={onModalClose}
                        subscription={subscription}
                        discounts={discounts}
                    />
                    <SimpleModal show={showDeleteCancellationSubscriptionModal} onClose={onModalClose} title="Delete Scheduled Cancellation?"
                        buttonTitle="Delete" buttonVariant="danger" onConfirm={onConfirmDeleteScheduledCancellation}
                        body={<span>Are you sure you want to delete the scheduled cancellation?</span>}
                    />
                    <UpdateTrialEndDateModal show={showUpdateTrialEndModal} onClose={onModalClose}
                                             subscription={subscription} />
                    <UpdateSubscriptionAutoChargeModal
                        show={showUpdateSubscriptionAutoChargeModal}
                        onClose={onModalClose}
                        subscription={subscription} />
                    <UpdateSubscriptionPaymentMethodModal
                        show={showUpdateSubscriptionPaymentMethodModal}
                        onClose={onModalClose}
                        subscription={subscription} />
                    <UpdateSubscriptionPaymentInstructionsModal
                        size="lg"
                        subscription={subscription}
                        show={showUpdateSubscriptionPaymentInstructionsModal}
                        onClose={onModalClose} />
                    <SubscriptionHistoryModal
                        show={showHistoryModal}
                        onClose={onModalClose}
                        subscription={subscription} />
                </>
            }
            </Loader>
        </BaseContainer>
    );
}

export default Subscription;
