import '../App.scss';
import React, { useState, useEffect, useContext } from 'react';
import {getAllBillableItems, serverFetch, serverPost} from '../helpers/server';
import {
    BaseContext, currencyFormatFromPrice, formatTerm,
    getDescriptionForDiscount, getLabelForSubscriptionLength,
    renderDescriptionForItemPricing,
    renderProductMetric
} from '../helpers/common';
import MapleTable from "./MapleTable";
import Link from "./Link";
import UpdateSubscriptionMetricUsageModal from "./modals/UpdateSubscriptionMetricUsageModal";
import moment from 'moment';
import CopyableComponent from "./CopyableComponent";
import Label from "./Label";
const _ = require('lodash');

function BundlePricingTable(props) {
    const { getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const [showUpdateUsageModal, setShowUpdateUsageModal] = useState(false);
    const [metricToUpdateUsage, setMetricToUpdateUsage] = useState(null);
    const [usageToUpdate, setUsageToUpdate] = useState(null);
    const [productPricingIds, setProductPricingIds] = useState([]);
    const [configMap, setConfigMap] = useState({});
    const [discountMap, setDiscountMap] = useState([]);
    const [productPricingsMap, setProductPricingsMap] = useState({});
    const [oneTimeBillables, setOneTimeBillables] = useState([]);
    const [showUsage, setShowUsage] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [billableItems, setBillableItems] = useState([]);
    const [metricUsageMap, setMetricUsageMap] = useState({});

    useEffect(() => {
        setShowUsage(props.showUsage || false);
    }, [props.showUsage]);

    useEffect(() => {
        setSubscription(props.subscription || false);
    }, [props.subscription]);

    useEffect(() => {
        if (!_.isNil(props.product_pricing_ids)) {
            setProductPricingIds(props.product_pricing_ids);
        } else if (!_.isUndefined(props.bundlePricing) && !_.isNil(props.bundlePricing)) {
            setProductPricingIds(_.map(props.bundlePricing.bundle_product_pricings, (bpp) => bpp.product_pricing_id));
        }
        const initialConfig = _.keyBy(props.configItems, 'product_metric_pricing_id');
        _.each(props.oneTimeBillables, (otb) => {
            _.each(otb.product_pricing.product_metric_pricings, (pmp) => {
                initialConfig[pmp.id] = {
                    num_licenses: otb.aggregate
                };
            })
        });
        setConfigMap(initialConfig);
    }, [props.bundlePricing, props.product_pricing_ids, props.oneTimeBillables, props.configItems])

    useEffect(() => {
        if (props.discounts && props.discounts.length > 0) {
            setDiscountMap(_.groupBy(props.discounts, 'item_pricing_id'));
        } else {
            setDiscountMap(null);
        }
    }, [props.discounts]);

    useEffect(() => {
        setOneTimeBillables(props.oneTimeBillables);
    }, [props.oneTimeBillables])

    useEffect(() => {
        async function inner() {
            const items = await getAllBillableItems(getApiUrl);
            if (items) {
                setBillableItems(items);
            }
        }
        inner();
    }, [])

    useEffect(() => {
        if (_.isEmpty(productPricingIds) && _.isEmpty(oneTimeBillables)) {
            return;
        }
        const allProductPricingIds = [...productPricingIds,..._.map(oneTimeBillables, (otb) => otb.product_pricing_id)];
        serverPost(getApiUrl(`/product_pricings/batch`), { ids: allProductPricingIds }).then((res) => {
            if (res) {
                setProductPricingsMap(_.keyBy(res, 'id'))
            }
        });
    }, [productPricingIds, oneTimeBillables, getApiUrl]);

    useEffect(() => {
        if (!showUsage || _.isEmpty(productPricingsMap) || _.isNil(subscription) || _.isEmpty(subscription)) {
            return;
        }
        fetchMetricUsages();
    }, [productPricingsMap, showUsage])

    const fetchMetricUsages = () => {
        serverFetch(getApiUrl(`/subscriptions/${subscription.id}/usages`), {}).then((res) => {
            if (res) {
                setMetricUsageMap(prevMap => {
                    const newMap = {...prevMap};
                    _.each(res.subscription_usages, r => {
                        newMap[`${r.metric.id}`] = _.find(r.usage, (u) => moment(u.period.start_date).isBefore(moment()) && moment(u.period.end_date).isAfter(moment()));
                    })
                    return newMap;
                })
            }
        });
    }

    const updateMetricUsage = (metric, usage) => {
        setMetricToUpdateUsage(metric);
        setUsageToUpdate(usage);
        setShowUpdateUsageModal(true);
    }

    const onModalClose = (didUpdate) => {
        setShowUpdateUsageModal(false);
        if (didUpdate) {
            setTimeout(() => {
                fetchMetricUsages();
            }, 1000)
            if (props.onUsageUpdated) {
                props.onUsageUpdated(true);
            }
        }
    }

    const renderProductPricing = (pricing, i) => {
        const oneTimeIds = _.map(oneTimeBillables, (otb) => otb.product_pricing_id);
        if (!_.includes(productPricingIds, pricing.id) && !_.includes(oneTimeIds, pricing.id)) {
            return;
        }
        const oneTimeConfig = _.find(oneTimeBillables, (otb) => otb.product_pricing_id === pricing.id);
        return (
            <React.Fragment key={i}>
                {
                    _.map(pricing.product_metric_pricings, (pmp, j) =>
                        <tr key={j} className="">
                            <td>
                                <span className="body1">
                                    {j === 0 &&
                                        <>
                                            {
                                                props.showPricingLinks1 ?
                                                    <Link href={getCompanySpecificUrl(`/pricing/${pricing.id}`)}>
                                                        <span
                                                            className="body2">{pricing.product.name}</span><span> - {pricing.name}</span>
                                                    </Link>
                                                : <><span className="body2">{pricing.product.name}</span><span> - {pricing.name}</span><CopyableComponent className="mt-2" value={ pricing.id }/></>
                                            }
                                        </>
                                    }
                                </span>
                            </td>
                            {
                                props.showProductMetricPricingID &&
                                    <td><CopyableComponent value={pmp.id}/></td>
                            }
                            <td>{ renderProductMetric(pmp, _.includes(oneTimeIds, pricing.id) ? oneTimeConfig: (_.has(configMap, pmp.id) ? configMap[pmp.id]: null)) }</td>
                            <td>
                                <span>{ renderDescriptionForItemPricing(pmp.item_pricing, false, pmp.item, billableItems) }</span>
                            </td>
                            {
                                discountMap &&
                                <td>{discountMap[pmp.item_pricing_id] ? getDescriptionForDiscount(discountMap[pmp.item_pricing_id][0], getCompanySpecificUrl, props.hideDiscountAdditionalDetails, props.displayDiscountExpiration) : "-"}</td>
                            }
                            {
                                showUsage &&
                                    <td>
                                        <span>{ metricUsageMap[`${pmp.metric_id}`] && metricUsageMap[`${pmp.metric_id}`].value.toLocaleString("en-US", { maximumFractionDigits: 4 }) }</span><br/>
                                        {
                                            pmp.metric.metering_rule.aggregator === "CUSTOMER_LAST_PERIOD" &&
                                                <Link onClick={() => updateMetricUsage(pmp.metric, metricUsageMap[pmp.metric_id])}>Update</Link>
                                        }
                                    </td>
                            }
                        </tr>
                    )
                }
            </React.Fragment>
        )
    }

    const hasNonOneTimePricings = !_.isEmpty(productPricingIds);

    return (
        <div className={props.className}>
            {
                props.term && hasNonOneTimePricings &&
                    <div className="body1"><strong>Term:</strong> { formatTerm(props.term) }</div>
            }
            {
                props.minimumSpend && props.minimumSpend.value_in_cents > 0 &&
                    <div className="body1"><strong>Minimum Spend:</strong> { currencyFormatFromPrice(props.minimumSpend) }</div>
            }
            <MapleTable>
                <MapleTable.Content>
                    <thead>
                        <tr>
                            <MapleTable.TH>Description</MapleTable.TH>
                            {
                                props.showProductMetricPricingID &&
                                    <MapleTable.TH>Metric Pricing ID</MapleTable.TH>
                            }
                            <MapleTable.TH>Metric/Quantity</MapleTable.TH>
                            <MapleTable.TH>Pricing</MapleTable.TH>
                            {
                                discountMap &&
                                <MapleTable.TH>Discount</MapleTable.TH>
                            }
                            {
                                showUsage &&
                                <MapleTable.TH>Usage</MapleTable.TH>
                            }
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {
                        !_.isEmpty(productPricingsMap) && _.map(productPricingsMap, (pricing, i) =>
                            renderProductPricing(pricing, i)
                        )
                    }
                    </tbody>
                </MapleTable.Content>
            </MapleTable>
            <UpdateSubscriptionMetricUsageModal
                show={showUpdateUsageModal}
                onClose={onModalClose}
                usage={usageToUpdate}
                metric={metricToUpdateUsage}
                subscription={subscription}/>
        </div>
    );
}

export default BundlePricingTable;
