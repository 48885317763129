import '../../App.scss';
import '../../css/invoice.scss';
import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import {useParams} from 'react-router-dom';
import { serverFetch, serverPatch, serverPost } from "../../helpers/server";
import {
    BaseContext,
    CustomerContext,
    renderPaymentMethod,
    isPaymentMethodExpiring,
    isPaymentMethodExpired,
    currencyFormatFromPrice,
    getPaymentMethodDescription,
    renderAddress,
    CustomerPortalContext, downloadBlob,
    UserContext
} from '../../helpers/common';
import { getSubscriptionStatus } from '../../helpers/subscriptions';
import {Button, Row} from "react-bootstrap";
import MoreButton from "../../components/MoreButton";
import Section from "../../components/Section";
import {useTranslation} from "react-i18next";
import SetupPaymentMethodModal from "../../components/modals/SetupPaymentMethodModal";
import MapleTable from "../../components/MapleTable";
import Label from "../../components/Label";
import moment from "moment/moment";
import variables from "../../App.scss";
import {getShareLinkUrl, renderStatusLabel} from "../../helpers/invoices";
import {ArrowDownTrayIcon, PencilSquareIcon} from "@heroicons/react/24/outline";
import Link from "../../components/Link";
import UpdateSubscriptionPaymentMethodModal from "../../components/modals/UpdateSubscriptionPaymentMethodModal";
import SubmitButton from "../../components/SubmitButton";
import BaseForm from "../../components/BaseForm";
import KeyValueDisplay2 from "../../components/KeyValueDisplay2";
import useGetCreditBalance from '../../helpers/hooks/api/useGetCreditBalance';
import CustomerPortalCreditSummaryTable from '../../components/CustomerPortalCreditSummaryTable';
import usePostFindCreditsApi from '../../helpers/hooks/api/usePostFindCreditsApi';
const _ = require('lodash');

function CustomerPortal() {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { portalId } = useParams();
    const [ searchParams ] = useSearchParams();
    let redirectStatus = searchParams.get('redirect_status');
    let customerId = searchParams.get('cid');
    const { isMapleUser } = useContext(UserContext);
    const { company, getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
    const { accessToken } = useContext(CustomerContext);
    const { portal, settings } = useContext(CustomerPortalContext)
    const [showSetupPaymentMethodModal, setShowSetupPaymentMethodModal] = useState(false);
    const [showUpdateSubscriptionPaymentMethodModal, setShowUpdateSubscriptionPaymentMethodModal] = useState(false);
    const [subscriptionsLoading, setSubscriptionsLoading] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);
    const [subscriptionToUpdate, setSubscriptionToUpdate] = useState(null);
    const [subscriptionToCancel, setSubscriptionToCancel] = useState(null);
    const [invoices, setInvoices] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState(null);
    const [customerDetails, setCustomerDetails] = useState({});
    const [details, setDetails] = useState({});
    const [showSubscriptions, setShowSubscriptions] = useState(false);
    const [editSubscriptions, setEditSubscriptions] = useState(false);
    const [cancelSubscriptions, setCancelSubscriptions] = useState(false);
    const [showInvoices, setShowInvoices] = useState(false);
    const [showPaymentMethods, setShowPaymentMethods] = useState(false);
    const [canAddPaymentMethod, setCanAddPaymentMethod] = useState(false);
    const [showCustomerInfo, setShowCustomerInfo] = useState(false);
    const [customerInfoEditable, setCustomerInfoEditable] = useState(false);
    const [editBillingInfo, setEditBillingInfo] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [hasMoreInvoices, setHasMoreInvoices] = useState(false);
    const [invalidOrExpired, setInvalidOrExpired] = useState(false);
    const showCredits = portal?.options?.credits?.show;

    useEffect(() => {
        setPageTitle(`Customer Portal`);
    }, []);
    
    useEffect(() => {
        setShowInvoices(portal.options.invoice.show);
        setShowSubscriptions(portal.options.subscription.show);
        setCancelSubscriptions(portal.options.subscription.cancellable);
        setShowPaymentMethods(portal.options.payment_methods.show);
        setCanAddPaymentMethod(portal.options.payment_methods.creatable);
        setShowCustomerInfo(portal.options.customer_info.show);
        setCustomerInfoEditable(portal.options.customer_info.editable);
    }, [portal])

    useEffect(() => {
        setDetails({
            'Contact Name': customerDetails.name,
            'Contact Email': customerDetails.email,
            'Organization Name': customerDetails.org_name,
            'Contact Phone': customerDetails.phone,
            'Billing Address': !_.isEmpty(customerDetails.address) ?
                renderAddress(customerDetails.address)
                : <span className="text-gray-400">No address set</span>,

        })
    }, [customerDetails]);

    useEffect(() => {
        if (!customerId) {
            setInvalidOrExpired(true);
        }
    }, [customerId])

    const authenticationErrorHandler = (errorRes, errorMessage) => {
        // Error happened. Very likely that the link expired.
        setInvalidOrExpired(true);
    }

    const fetchData = async (skipCache=false) => {
        if (!customerId) {
            return;
        }
        const options = {
            skipCache: skipCache,
            accessToken: accessToken,
            suppressUnauthenticated: true
        }
        if (showPaymentMethods) {
            serverFetch(getApiUrl(`/customers/${customerId}/payment_methods`), options, authenticationErrorHandler).then((res) => {
                if (res) {
                    setPaymentMethods(res.methods);
                }
            });
        }
        if (showCustomerInfo || canAddPaymentMethod) {
            serverFetch(getApiUrl(`/customers/${customerId}`), options, authenticationErrorHandler).then((res) => {
                if (res) {
                    setCustomerDetails(res);
                }
            });
        }
       
    }

    const fetchSubscriptions = () => {
        if (!portal || !showSubscriptions || !customerId) {
            return;
        }
        const params = {
            company_id: company.id,
            sort_key: "createdAtDesc",
            pagination: {
                from_key: null,
                limit: 50
            },
            query: {
                customer_id: customerId,
                statuses: ["ACTIVE"]
            },
            include_meta: true
        }
        const options = {
            accessToken: accessToken,
            suppressUnauthenticated: true
        }
        serverPost(getApiUrl("/subscriptions/find"), params, options, authenticationErrorHandler).then((res) => {
            if (res) {
                setSubscriptions(res.results || []);
            }
            setSubscriptionsLoading(false);
        });
    };

    const fetchInvoices = () => {
        if (!portal || !showInvoices || !customerId) {
            return;
        }
        const limit = 20;
        const params = {
            sort_key: "createdAtDesc",
            pagination: {
                from_key: null,
                limit: limit
            },
            query: {
                customer_id: customerId,
            },
            include_meta: true
        }
        const options = {
            accessToken: accessToken,
            suppressUnauthenticated: true
        }
        serverPost(getApiUrl("/invoices/find"), params, options, authenticationErrorHandler).then((res) => {
            if (res) {
                const results = res.results || [];
                setInvoices(results);
                setHasMoreInvoices(results.length === limit);
            }
        });
    };

    const { creditBalance, isCreditBalanceLoading, onCreditBalanceSearch } =
        useGetCreditBalance({
            customerID: customerId,
            options: {
                accessToken,
                disableCache: true,
            },
        });

    const {totalUnpaginatedCreditsCount, isCreditsLoading, onCreditsSearch } = usePostFindCreditsApi({
        limit: 1,
        options: {
            accessToken,
        }
    })

    const isCreditBalanceEmpty = !creditBalance?.active_credits?.length && !isCreditBalanceLoading;

    const creditsActionOptions = useMemo(() => {
        const options = [];
        if (showCredits && totalUnpaginatedCreditsCount > 0) {
            options.push({
                variant: "alink",
                label: t("credits.actions.view_details"),
                link: getCompanySpecificUrl(`/portal/${portal.id}/credits?token=${accessToken}`),
            })
            options.push({
                variant: "alink",
                label: t("credits.actions.view_usage"),
                link: getCompanySpecificUrl(`/portal/${portal.id}/credits_log?token=${accessToken}`),
            });
        }
        return options;
    }, [showCredits, portal.id, accessToken, totalUnpaginatedCreditsCount]);

    useEffect(() => {
        if (portal) {
            fetchData(true);
        }
    }, [portal, showPaymentMethods, showCustomerInfo, canAddPaymentMethod]);

    useEffect(() => {
        if (portal) {
            fetchSubscriptions();
            fetchInvoices();
        }
    }, [portal, showSubscriptions, showInvoices]);

    useEffect(() => {
        if (showCredits) {
            onCreditBalanceSearch();
            onCreditsSearch({keepPreviousData: false});
        }
    }, [showCredits]);

    useEffect(() => {
        if (_.isNil(redirectStatus)) {
            return;
        }
        setTimeout(() => {
            let urlParser = new URL(window.location.href);
            urlParser.searchParams.delete('setup_intent');
            urlParser.searchParams.delete('setup_intent_client_secret');
            urlParser.searchParams.delete('redirect_status');
            navigate(urlParser.pathname + urlParser.search, { replace: true });
        }, 100);
    }, [redirectStatus])

    const onModalClose = (didUpdate) => {
        setShowSetupPaymentMethodModal(false);
        setShowUpdateSubscriptionPaymentMethodModal(false);
        if (didUpdate) {
            fetchData(true);
        }
    }

    const onPaymentActionSelected = (action, paymentMethod) => {
        if (action === "set_default") {
            makePaymentMethodDefault(paymentMethod?.id);
        }
    };

    const makePaymentMethodDefault = (paymentMethodID) => {
        const options = {
            skipCache: true,
            accessToken: accessToken,
            suppressUnauthenticated: true,
        };
        const updateData = {
            default: true,
        };
        serverPatch(
            getApiUrl(
                `/customers/${customerId}/payment_methods/${paymentMethodID}`
            ),
            updateData,
            options,
            authenticationErrorHandler
        ).then((res) => {
            if (res) {
                fetchData(true);
            }
        });
    };

    const updateCustomerDetails = (data) => {
        const options = {
            accessToken: accessToken,
            suppressUnauthenticated: true
        }
        serverPatch(getApiUrl(`/customers/${customerDetails.id}`), data, options, authenticationErrorHandler).then((res) => {
            if (res) {
                setCustomerDetails(res);
                setIsEditing(false);
            }
        });
    }

    const goToInvoice = (event, invoice) => {
        window.open(
            getShareLinkUrl(invoice, getCompanySpecificUrl),
            '_blank'
        );
    }

    const downloadInvoicePDF = (event, invoice) => {
        serverPost(getApiUrl(`/invoices/${invoice.uuid}/pdf`), {}, { noJson: true }).then((res) => {
            downloadBlob(res, `Invoice-${invoice.number}`);
        })
    }

    const paymentActionOptions = [
        { id: "set_default", label: "Set as default" }
    ]

    let brandingLogo = null;
    let brandColor = variables.primaryColor;
    let brandBackgroundColor = variables.darkGrayColor;
    if (settings.branding) {
        brandingLogo = settings.branding.logo_url || null;
        brandColor = settings.branding.color || variables.primaryColor;
        brandBackgroundColor = settings.branding.color || variables.darkGrayColor;
    }

    const invoicesUrl = getCompanySpecificUrl(`/portal/${portalId}/invoices?token=${accessToken}`)

    const renderInvalidOrExpired = () => {
        return (
            <div className="max-w-xl">
                <p className="mt-8 text-2xl font-semibold">Billing Portal Link is Invalid or Expired</p>
                <p className="mt-6 text-sm">
                    Please reach out to the company for assistance.
                </p>
            </div>
        )
    }

    const renderContent = () => {
        return (
            <div>
                <div className="max-w-3xl">
                    {
                        showCustomerInfo &&
                        <Section title="Billing Information">
                            <div className="mt-2">
                                {
                                    isEditing &&
                                    <BaseForm initialFormFields={customerDetails} onSubmit={updateCustomerDetails}>
                                        <div className="flex flex-col gap-2">
                                            <div className="flex flex-row items-center">
                                                <div className="grow">
                                                    <p className="text-sm font-semibold">Details</p>
                                                    <p className="text-sm text-gray-500">Update your information on file
                                                        with {company.name}</p>
                                                </div>
                                                <div className="flex flex-row gap-1 items-start">
                                                    <Button variant="text-danger"
                                                            onClick={() => setIsEditing(false)}>Cancel</Button>
                                                    <SubmitButton>Save</SubmitButton>
                                                </div>
                                            </div>
                                            <div className="flex flex-row">
                                                <div className="grow">
                                                    <Row>
                                                        <BaseForm.Input colSpan="6" type="text" name="name"
                                                                        label="Contact Name"/>
                                                        <BaseForm.Input colSpan="6" type="text" name="email"
                                                                        label="Email" transformations={["lowercase", "trim"]}
                                                                        validations={{
                                                                            required: true,
                                                                            validEmail: true
                                                                        }}/>
                                                        <BaseForm.Input colSpan="6" type="text" name="org_name"
                                                                        label="Organization Name (optional)"/>
                                                        <BaseForm.Input colSpan="6" type="text" name="phone"
                                                                        label="Phone Number (optional)"/>
                                                        <BaseForm.Input colSpan="6" type="simple_address" name="address"
                                                                        label="Address (optional)"/>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    </BaseForm>
                                }
                                {
                                    !isEditing &&
                                    <div className="flex flex-col gap-2">
                                        <div className="flex flex-row items-center">
                                            <div className="grow">
                                                <p className="text-sm font-semibold">Details</p>
                                                <p className="text-sm text-gray-500">Information about you
                                                    with {company.name}</p>
                                            </div>
                                            {
                                                customerInfoEditable &&
                                                <div>
                                                    <Button
                                                        variant="text-primary"
                                                        onClick={() => setIsEditing(true)}
                                                    ><i className="fa fa-edit"/> Edit</Button>
                                                </div>
                                            }
                                        </div>
                                        <KeyValueDisplay2 items={details}/>
                                    </div>
                                }
                            </div>
                        </Section>
                    }
                    {
                        showSubscriptions &&
                        <Section title="Subscriptions" className="mt-4" loading={subscriptionsLoading}>
                            {
                                !_.isEmpty(subscriptions) ?
                                    <MapleTable>
                                        <MapleTable.Content>
                                            <thead>
                                            <tr>
                                                <th>Plan</th>
                                                <th>Status</th>
                                                <th>Renews</th>
                                                <th>Next Invoice Date</th>
                                                {showPaymentMethods && <th className="no-stretch d-none d-md-table-cell">Payment Method</th>}
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200">
                                            {
                                                _.map(subscriptions, (row, i) =>
                                                    <tr key={i}>
                                                        <td>
                                                            <div>
                                                                {
                                                                    _.map(row.product_pricings, (pp, j) =>
                                                                        <div key={j}>
                                                                            <span
                                                                                className="text-sm font-semibold text-gray-700">{pp.product.name}</span><br/>
                                                                            <span
                                                                                className="text-sm font-normal text-gray-500">{pp.external_name || pp.name}</span>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </td>
                                                        <td className="no-stretch">{getSubscriptionStatus(row)}</td>
                                                        <td>
                                                            {
                                                                row.renewal_date &&
                                                                <span>{moment(row.renewal_date).format("MMM D, YYYY")}</span>
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                row.next_invoice_date &&
                                                                <span>{moment(row.next_invoice_date).format("MMM D, YYYY")}</span>
                                                            }
                                                        </td>
                                                        {
                                                            showPaymentMethods && 
                                                                <td className="no-stretch d-none d-md-table-cell">
                                                                    <div className="flex flex-row gap-3 items-center">
                                                                        {
                                                                            row.payment_method_id ?
                                                                                <span>{renderPaymentMethod(t, _.find(paymentMethods, (pm) => pm.id === row.payment_method_id))}</span>
                                                                                : <span className="gray2">Default Method</span>
                                                                        }
                                                                        {
                                                                            editSubscriptions &&
                                                                            <Link onClick={() => {
                                                                                setSubscriptionToUpdate(row);
                                                                                setShowUpdateSubscriptionPaymentMethodModal(true);
                                                                            }}><PencilSquareIcon className="h-5 w-5"/></Link>
                                                                        }
                                                                    </div>
                                                                </td>
                                                        }
                                                        <td>
                                                            <Link
                                                                href={getCompanySpecificUrl(`/portal/${portalId}/subscription/${row.id}?token=${accessToken}`)}>
                                                                    View
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                        </MapleTable.Content>
                                    </MapleTable>
                                    : <span className={"text-sm text-gray-500"}>No active subscriptions</span>
                            }
                        </Section>
                    }
                    {
                        showPaymentMethods &&
                        <Section title="Payment Methods" className="mt-4" actions={canAddPaymentMethod && [{
                            variant: "primary",
                            icon: "fa-plus",
                            label: "Add Payment Method",
                            onClick: () => setShowSetupPaymentMethodModal(true)
                        }]}>
                            {
                                _.isEmpty(paymentMethods) ?
                                    <p className="text-sm text-gray-500">No payment
                                        methods. {canAddPaymentMethod ? "Add a new one" : ""}</p>
                                    : <MapleTable>
                                        <MapleTable.Content>
                                            <thead>
                                            <tr>
                                                <th>Payment Method</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200">
                                            {
                                                _.map(paymentMethods, (row, i) =>
                                                    <tr key={i}>
                                                        <td>{renderPaymentMethod(t, row)}</td>
                                                        <td>
                                                            <div className="d-flex flex-row gap-3">
                                                                {
                                                                    row.default &&
                                                                    <Label.Success><i className="fa fa-check mr-1"/>Default</Label.Success>
                                                                }
                                                                {
                                                                    isPaymentMethodExpiring(row) &&
                                                                    <Label.Warning>Expiring</Label.Warning>
                                                                }
                                                                {
                                                                    isPaymentMethodExpired(row) &&
                                                                    <Label.Danger>Expired</Label.Danger>
                                                                }
                                                            </div>
                                                        </td>
                                                        {canAddPaymentMethod && !row.default && (
                                                            <td className="text-end">
                                                                <div>
                                                                    <MoreButton
                                                                        items={ paymentActionOptions }
                                                                        onSelect={(action) => onPaymentActionSelected(action, row)}
                                                                    />
                                                                </div>
                                                            </td>
                                                        )}
                                                    </tr> 
                                                )
                                            }
                                            </tbody>
                                        </MapleTable.Content>
                                    </MapleTable>
                            }
                        </Section>
                    }
                    {showCredits && (
                        <Section title="Credits" className="mt-4" actions={creditsActionOptions} loading={isCreditBalanceLoading || isCreditsLoading}>
                            {isCreditBalanceEmpty ? (
                                <span className={"text-sm text-gray-500"}>{t("credits.table.no_active_credits")}</span>
                            ) : (
                                <CustomerPortalCreditSummaryTable
                                    creditBalanceData={{
                                        creditBalance,
                                        isLoading: isCreditBalanceLoading,
                                    }}
                                />
                            )}
                        </Section>
                    )}
                    {
                        showInvoices &&
                        <Section title="Invoices" className="mt-4">
                            {
                                !_.isEmpty(invoices) ?
                                    <MapleTable>
                                        <MapleTable.Content>
                                            <thead>
                                            <tr>
                                                <MapleTable.TH>Date</MapleTable.TH>
                                                <MapleTable.TH>Amount</MapleTable.TH>
                                                <MapleTable.TH>Status</MapleTable.TH>
                                                <MapleTable.TH>Due Date</MapleTable.TH>
                                                <MapleTable.TH></MapleTable.TH>
                                            </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200">
                                            {
                                                _.map(invoices, (row, i) =>
                                                    <tr key={i}>
                                                        <td className="">{moment(row.invoice_date).format("MMM D, YYYY")}</td>
                                                        <td>{currencyFormatFromPrice(row.total)}</td>
                                                        <td className="">{renderStatusLabel(row, getCompanySpecificUrl)}</td>
                                                        <td className="">{moment(row.due_date).format("MMM D, YYYY")}</td>
                                                        <td className="w-px whitespace-nowrap">
                                                            <div className="flex flex-row gap-3">
                                                                <Link
                                                                    onClick={(event) => goToInvoice(event, row)}>View</Link>
                                                                <Link
                                                                    onClick={(event) => downloadInvoicePDF(event, row)}>
                                                                    <ArrowDownTrayIcon className="h-5 w-5"/>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            {
                                                hasMoreInvoices &&
                                                <tr>
                                                    <td colSpan="5">
                                                        <div className="flex flex-row justify-content-center">
                                                            <Link href={invoicesUrl}>View all</Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                            </tbody>
                                        </MapleTable.Content>
                                    </MapleTable>
                                    : <span className={"text-sm text-gray-500"}>No invoices yet</span>
                            }
                        </Section>
                    }
                </div>
                <SetupPaymentMethodModal
                    show={showSetupPaymentMethodModal}
                    onClose={onModalClose}
                    paymentConfig={settings.payment_config}
                    customer={customerDetails}
                    company={company}
                    redirectUrl={window.location.href}
                />
                <UpdateSubscriptionPaymentMethodModal
                    show={showUpdateSubscriptionPaymentMethodModal}
                    onClose={onModalClose}
                    subscription={subscriptionToUpdate}/>
            </div>
        );
    }

    if (invalidOrExpired) {
        return renderInvalidOrExpired();
    } else {
        return renderContent();
    }
}

export default CustomerPortal;
