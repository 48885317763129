import React, {useContext, useEffect, useState} from "react";
import BaseSideModal from "./BaseSideModal";
import moment from "moment";
import {CheckIcon} from "@heroicons/react/20/solid";
import CopyableComponent from "../CopyableComponent";
import CopyableContainer from "../CopyableContainer";
import {BaseContext} from "../../helpers/common";
const _ = require('lodash');

function BillableEventModal(props) {
    const { getCompanySpecificUrl } = useContext(BaseContext);
    const [currentEvent, setCurrentEvent] = useState(props.billableEvent);
    const [billableMetricRecords, setBillableMetricRecords] = useState([]);

    useEffect(() => {
        setCurrentEvent(props.billableEvent)
    }, [props.billableEvent]);

    useEffect(() => {
        const seen = new Set()
        const filteredBillableMetricRecords = _.filter(currentEvent.billable_metric_record_events, (event) => {
            if (!event.subscription_id || seen.has(event.subscription_id)) {
                return false;
            } else {
                seen.add(event.subscription_id);
                return true;
            }
        })
        setBillableMetricRecords(filteredBillableMetricRecords)
    }, [currentEvent])

    return (
        <BaseSideModal {...props}>
            <div className={"flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"}>
                <BaseSideModal.Header title={"Billable Event"}/>
                <BaseSideModal.Body>
                    <div className={"text-md font-semibold text-gray-700"}>Transaction ID</div>
                    <span className={"text-sm"}>{currentEvent.transaction_id}</span><br/><br/>
                    <div className={"text-md font-semibold text-gray-700"}>Item</div>
                    <span className={"text-sm"}>{currentEvent.item.name}</span>
                    <CopyableComponent value={currentEvent.item_id} />
                    <br/>
                    <div className={"text-md font-semibold text-gray-700"}>Event Timestamp</div>
                    <span
                        className={"text-sm"}>{moment(currentEvent.timestamp).format("MMM D, YYYY h:mm:ss a")}</span><br/><br/>
                    <div className={"text-md font-semibold text-gray-700"}>Timeline</div>
                    <br/>
                    <div className={"flow-root"}>
                        <ul className="-mb-8">
                            <li>
                                <div className="relative pb-8">
                                        <span aria-hidden="true"
                                              className="absolute left-3 top-3 -ml-px h-full w-0.5 bg-gray-200"/>
                                    <div className="relative flex space-x-3">
                                        <div>
                                                 <span
                                                     className={'flex h-6 w-6 items-center justify-center rounded-full ring-8 ring-white bg-green-400'}
                                                 >
                                                    <CheckIcon aria-hidden="true" className="h-3 w-3 text-white"/>
                                                 </span>
                                        </div>
                                        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-0.5">
                                            <div>
                                                <p className="text-sm text-gray-500">Ingested</p>
                                            </div>
                                            <div className="whitespace-nowrap text-right text-sm text-gray-500">
                                                <span
                                                    className={"text-sm"}>{moment(currentEvent.created_at).format("MMM D, YYYY h:mm:ss a")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="relative pb-8">
                                    {
                                        billableMetricRecords.length > 0 &&
                                        <span aria-hidden="true"
                                              className="absolute left-3 top-3 -ml-px h-full w-0.5 bg-gray-200"/>
                                    }
                                    <div className="relative flex space-x-3">
                                        <div>
                                                <span
                                                    className={'flex h-6 w-6 items-center justify-center rounded-full ring-8 ring-white bg-green-400'}
                                                >
                                                    <CheckIcon aria-hidden="true" className="h-3 w-3 text-white"/>
                                                </span>
                                        </div>
                                        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-0.5">
                                            <div>
                                                <p className="text-sm text-gray-500">
                                                    Associated with <a
                                                    href={getCompanySpecificUrl(`/customer/${currentEvent.customer_id}`)}
                                                    target={"_blank"}
                                                    rel="noreferrer">{currentEvent.customer.display_name}</a>
                                                </p>
                                                <CopyableComponent value={currentEvent.customer_id}/>
                                            </div>
                                            <div className="whitespace-nowrap text-right text-sm text-gray-500">
                                                <span
                                                    className={"text-sm"}>{moment(currentEvent.created_at).format("MMM D, YYYY h:mm:ss a")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {_.map(billableMetricRecords, (event, i) =>
                                <li>
                                    <div className="relative pb-8">
                                        {i !== billableMetricRecords.length - 1
                                            && <span aria-hidden="true"
                                                     className="absolute left-3 top-3 -ml-px h-full w-0.5 bg-gray-200"/>
                                        }
                                        <div className="relative flex space-x-3">
                                            <div>
                                  <span
                                      className={'flex h-6 w-6 items-center justify-center rounded-full ring-8 ring-white bg-green-400'}
                                  >
                                    <CheckIcon aria-hidden="true" className="h-3 w-3 text-white"/>
                                  </span>
                                            </div>
                                            <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-0.5">
                                                <div>
                                                    <p className="text-sm text-gray-500">
                                                        Associated with <a
                                                        href={getCompanySpecificUrl(`/subscription/${event.subscription_id}`)}
                                                        target={"_blank"}
                                                        rel="noreferrer">subscription</a>
                                                    </p>
                                                    <CopyableComponent value={event.subscription_id}/>
                                                </div>
                                                <div className="whitespace-nowrap text-right text-sm text-gray-500">
                                                <span
                                                    className={"text-sm"}>{moment(event.created_at).format("MMM D, YYYY h:mm:ss a")}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                    <br/>
                    <div className={"text-md font-semibold text-gray-700"}>Properties</div>
                    <br/>
                    <CopyableContainer copyableValue={JSON.stringify(currentEvent.properties, null, 2)}/>
                </BaseSideModal.Body>
            </div>
        </BaseSideModal>
    )
}

export default BillableEventModal;