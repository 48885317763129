import '../../App.scss';
import '../../css/modals.scss';
import '../../css/forms.scss';
import React, {useContext, useEffect, useState} from 'react';
import BaseForm from '../BaseForm';
import BaseSideModal from './BaseSideModal';
import SubmitButton from '../SubmitButton';
import Columns from "../../components/Columns";
import Notification from '../../components/Notification';
import {BaseContext, IntegrationContext} from '../../helpers/common';
import {serverPost} from '../../helpers/server';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {ChevronDoubleDownIcon} from "@heroicons/react/24/outline";
import {useParams} from "react-router-dom";
const _ = require('lodash');

function UpdateIntegrationReferenceModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const { integration } = useContext(IntegrationContext);
    let { entity } = useParams();
    entity = _.replace(_.upperCase(entity), " ", "_");

    const remoteLabel = (remoteReference) => {
        let label = "";
        if (remoteReference.additional_data) {
            label = remoteReference.additional_data.display_name || remoteReference.additional_data.email
        }
        return (
            <div className="flex flex-row gap-2 items-center">
                <div className="flex flex-col grow">{ label }</div>
                <div className="h-4 shrink-0"></div>
            </div>
        )
    }

    const loadRemoteReferences = async (query) => {
        if (query.length < 3) {
            return [];
        }
        const remotePromise = serverPost(getApiUrl(`/integrations/${integration.id}/remote_auto_complete`), {
            entity_type: entity,
            search_term: query
        });
        const remoteResult = await remotePromise;
        return remoteResult || [];
    }

    const updateIntegrationReference = async (data, errorHandler) => {
        const mappingData = {
            reference_id: props.reference.id,
            remote_id: data.remote_reference.remote_id
        }
        serverPost(getApiUrl(`/integrations/${integration.id}/update_reference_mapping`), mappingData, {}, errorHandler).then(res => {
            if (res) {
                props.onClose(true);
                Notification.Success("Updated Successfully");
            }
        });
    }

    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form onSubmit={updateIntegrationReference}>
                <BaseSideModal.Header title={"Update Integration Mapping"}/>
                <BaseSideModal.Body>
                    {
                        props.reference &&
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-1">
                                    <span className="text-sm font-semibold">Map Maple customer to { integration.name }</span>
                                    <Columns.CustomerName customer={props.reference.customer}/>
                                </div>
                                <div className="align-self-center">
                                    <ChevronDoubleDownIcon className="h-8 w-8 text-gray-500"/>
                                </div>
                            </div>
                    }
                    <Row className="mt-4">
                        <BaseForm.Input
                            colSpan="12" name={"remote_reference"} label={"Search"} type="selector"
                            loadOptions={loadRemoteReferences} getOptionLabel={remoteLabel}
                            getOptionValue={(r) => r.remote_id} required />
                    </Row>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <SubmitButton variant="primary">Update</SubmitButton>
                        </Col>
                    </Row>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default UpdateIntegrationReferenceModal;
