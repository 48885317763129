import '../../App.scss';
import React, {useContext, useEffect, useState} from 'react';
import {
    BaseContext,
    currencyFormatFromPrice,
    getConfigActionTypeKey,
    getCustomerNameOrEmail,
    getInvoicePaymentTermDescription, getNameForProviderType,
    getPaymentMethodDescription,
    getStatusForStep,
    renderCampaignStatusLabel,
} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import {Button, Table} from 'react-bootstrap';
import {useParams} from "react-router-dom";
import moment from "moment/moment";
import Link from "../../components/Link";
import {serverFetch, serverPost} from "../../helpers/server";
import { useTranslation } from "react-i18next";
import DropdownMenu from "../../components/DropdownMenu";
import {WrenchScrewdriverIcon} from "@heroicons/react/20/solid";
import SimpleModal from "../../components/modals/SimpleModal";
import Tags from "../../components/Tags";
import {getDescriptionForSubscriptionCancelReason} from "../../helpers/subscriptions";
import KeyValueDisplay2 from "../../components/KeyValueDisplay2";

const _ = require('lodash');

function Campaign(props) {
    const {campaignId} = useParams();
    const { t } = useTranslation('common');
    const {company, getApiUrl, getCompanySpecificUrl, setPageTitle} = useContext(BaseContext);
    const [steps, setSteps] = useState([]);
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [proposalDetails, setProposalDetails] = useState({});
    const [campaign, setCampaign] = useState({});
    const [showStopModal, setShowStopModal] = useState(false);
    const [campaignIdentifier, setCampaignIdentifier] = useState('');
    const [details, setDetails] = useState({});

    useEffect(() => {
        setPageTitle(`Email Campaign for ${campaignIdentifier}`);
    }, [campaignIdentifier]);

    const fetchData = (skipCache = false) => {
        serverFetch(getApiUrl(`/campaigns/${campaignId}`), {skipCache: skipCache}).then(async (res) => {
            if (res) {
                setCampaign(res);
                setSteps(_.sortBy(res.steps, s => s.step_number));

                if (res.type === "proposal_sign_reminder") {
                    setProposalDetails(res.proposal);
                    if (res.proposal) {
                        setCampaignIdentifier(res.proposal.title)
                    } else {
                        setCampaignIdentifier("Deleted Contract");
                    }

                } else {
                    setInvoiceDetails(res.invoice)
                    setCampaignIdentifier(res.invoice.number)
                }
            }
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (_.isEmpty(campaign)) {
            return;
        }
        let data = {
            'Customer': <Link href={getCompanySpecificUrl(`/customer/${campaign.customer.id}`)}>{getCustomerNameOrEmail(campaign.customer)}</Link>,
        }
        if (campaign.invoice) {
            data = _.merge(data, {
                'Invoice': <Link href={getCompanySpecificUrl(`/invoice/${campaign.invoice.uuid}`)}>{ campaign.invoice.number }</Link>
            })
        }
        data = _.merge(data, {
            'Amount': currencyFormatFromPrice(campaign.amount),
            'Progress': `${campaign.current_step}/${campaign.total_steps} steps`
        })
        setDetails(data);
    }, [campaign]);

    const isActiveCampaign = campaign.status === 'ACTIVE'
    const actionOptions = [
        isActiveCampaign && {id: "stop_campaign", label: "Stop Campaign", className: "error"},
    ]

    const onActionSelected = (type) => {
        if (type === "stop_campaign") {
            setShowStopModal(true);
        }
    }

    const stopCampaign = () => {
        serverPost(getApiUrl(`/campaigns/${campaignId}/stop`), {}).then(res => {
            if (res) {
                fetchData(true);
            }
        })
    }

    const renderActions = () => {
        return (
            <DropdownMenu className="p-2" items={actionOptions} onClick={onActionSelected}>
                <div className="flex flex-row gap-1 items-center">
                    <WrenchScrewdriverIcon className="h-4 w-4"/>
                    <span>Actions</span>
                </div>
            </DropdownMenu>
        )
    }

    const renderDetails = (step) => {
        if (step.payment_transaction) {
            if (step.payment_transaction.status === "FAILED") {
                return <Link href={getCompanySpecificUrl(`/payment/${step.payment_transaction.id}`)}>View Failed Payment</Link>
            } else {
                return <Link href={getCompanySpecificUrl(`/payment/${step.payment_transaction.id}`)}>View Payment</Link>
            }
        } else if (step.notification_log) {
            return <>Email Notification Sent</>
        }
        return <></>
    }

    const genDurationDesc = (amount, unitName) => {
        if (amount === 1) {
            return `${amount} ${unitName}`
        } else {
            return `${amount} ${unitName}s`
        }
    }

    const renderTimeDelinquentDesc = (campaign) => {
        if (_.includes(["RECOVERED"], campaign.status)) {
            return;
        }
        const referenceTime = moment(campaign.time_from_reference_data).utcOffset(0, true);
        const duration = moment.duration(moment().diff(referenceTime));
        let delinquentDescription = "Delinquent";
        if (duration.years() > 0) {
            delinquentDescription += `, ${genDurationDesc(duration.years(), 'year')}`
        }
        if (duration.months() > 0) {
            delinquentDescription += `, ${genDurationDesc(duration.months(), 'month')}`
        }
        if (duration.weeks() > 0) {
            delinquentDescription += `, ${genDurationDesc(duration.weeks(), 'week')}`
        }
        if (duration.days() > 0) {
            const days = duration.days() - (7 * duration.weeks())
            if (days > 0) {
                delinquentDescription += `, ${genDurationDesc(days, 'day')}`
            }
        }
        if (duration.hours() > 0) {
            delinquentDescription += `, ${genDurationDesc(duration.hours(), 'hour')}`
        }
        if (duration.minutes() > 0) {
            delinquentDescription += `, ${genDurationDesc(duration.minutes(), 'minute')}`
        }
        return delinquentDescription;
    };

    const hasActions = !_.isEmpty(_.filter(actionOptions, a => a));
    return (
        <BaseContainer>
            <ContentContainer>
                <Section title={`Email Campaign for ${campaignIdentifier}`}
                         subtitle={renderTimeDelinquentDesc(campaign)}
                         right={
                             <div className="grow flex flex-row gap-3 items-right">
                                 <div className="grow">
                                     {renderCampaignStatusLabel(campaign)}
                                 </div>
                                 <div className="grow-0 shrink-0 flex flex-row gap-3">
                                     { hasActions && renderActions() }
                                 </div>
                             </div>}>
                    {
                        !_.isEmpty(details) &&
                            <div className="mt-2 max-w-xl">
                                <KeyValueDisplay2 items={details} />
                            </div>
                    }
                    {
                        <MapleTable>
                            <MapleTable.Content>
                                <thead>
                                <tr>
                                    <th>Scheduled Date</th>
                                    <th>Completed Date</th>
                                    <th>Action</th>
                                    <th>Status</th>
                                    <th>Details</th>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {
                                    _.map(steps, (step, i) =>
                                        <tr key={i}>
                                            <td>{moment(step.enqueued_at).format("D MMMM, YYYY, h:mm:ssa")}</td>
                                            <td>{step.completed_at !== "0001-01-01T00:00:00Z" ? moment(step.completed_at).format("D MMMM, YYYY, h:mm:ssa") : ""}</td>
                                            <td>{t(getConfigActionTypeKey(step.action_type))}</td>
                                            <td>{getStatusForStep(step)}</td>
                                            <td>{renderDetails(step)}</td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </MapleTable.Content>
                        </MapleTable>
                    }
                </Section>
            </ContentContainer>
            <SimpleModal
                title={"Confirm stop campaign?"}
                body={
                    <div>
                        <span>Are you sure you want to stop this campaign? Any future campaign steps will not be executed.</span>
                    </div>
                }
                buttonVariant={"danger"}
                buttonTitle={"Stop Campaign"}
                show={showStopModal}
                onClose={setShowStopModal}
                onConfirm={() => stopCampaign()}
            />
        </BaseContainer>
    );
}

export default Campaign;
