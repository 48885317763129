import '../../App.scss';
import '../../css/modals.scss';
import React, { useEffect, useState, useContext } from 'react';
import BaseModal from './BaseModal';
import { useTranslation } from 'react-i18next';
import { downloadBlob, BaseContext } from '../../helpers/common';
import { Row, Col, Button } from 'react-bootstrap';
import { serverPost } from '../../helpers/server';
import moment from 'moment';
const _ = require("lodash");

function BaseCSVExportModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation(['common']);
    const [fields, setFields] = useState([]);
    const [allFields, setAllFields] = useState([]);
    const [itemIDs, setItemIDs] = useState([]);
    const [query, setQuery] = useState(null);
    const [selectedFields, setSelectedFields] = useState([]);

    useEffect(() => {
        setFields(props.fields);

        let _allFields = [{ id: "csvcheckall", value: "csvcheckall" }];
        _.each(fields, (fs, j) => {
            _.each(fs.fields, (f, i) => {
                _allFields.push(f);
            })
        })
        setAllFields(_allFields);

        let preselectedFields = null
        if (props.cacheKey) {
            const storedList = localStorage.getItem(props.cacheKey);
            if (storedList) {
                preselectedFields = _.intersection(JSON.parse(storedList), _.map(_allFields, (f) => f.value));
            }
        }
        if (!preselectedFields) {
            let newSelectedFields = _.map(_allFields, (f) => f.value);
            newSelectedFields = newSelectedFields.concat(["csvcheckall"]);
            preselectedFields = newSelectedFields;
        }
        setSelectedFields(preselectedFields);
    }, [props.fields])

    useEffect(() => {
        setItemIDs(props.itemIDs);
    }, [props.itemIDs]);

    useEffect(() => {
        setQuery(props.query);
    }, [props.query]);

    const updateChecked = (event) => {
        if (event.target.value === "csvcheckall") {
            let newSelectedFields = [];
            if (event.target.checked) {
                newSelectedFields = _.map(allFields, (f) => f.value);
                newSelectedFields = newSelectedFields.concat(["csvcheckall"]);
            }
            setSelectedFields(newSelectedFields);
        } else {
            const newSelectedFields = [...selectedFields];
            if (event.target.checked) {
                newSelectedFields.push(event.target.value);
            } else {
                let index = newSelectedFields.indexOf(event.target.value);
                if (index > -1) {
                    newSelectedFields.splice(index, 1);
                }
                index = newSelectedFields.indexOf("csvcheckall");
                if (index > -1) {
                    newSelectedFields.splice(index, 1);
                }
            }
            setSelectedFields(newSelectedFields);
        }
    }

    const onExport = (selectedFields) => {
        let filteredFields = [...selectedFields];
        _.pull(filteredFields, "csvcheckall");

        const originalFieldOrder = _.map(allFields, (f) => f.value);
        filteredFields = _.sortBy(filteredFields, (f) => _.indexOf(originalFieldOrder, f));

        if (props.cacheKey) {
            localStorage.setItem(props.cacheKey, JSON.stringify(selectedFields));
        }

        const filename = `${props.filenamePrefix}-${moment().format("MMM-DD-YYYY")}.csv`;
        const data = {
            format: "csv",
            fields: filteredFields,
        }
        if (props.useQuery) {
            data['query'] = query;
        } else if (!_.isEmpty(itemIDs)) {
            data['ids'] = itemIDs;
        }
        if (props.sort) {
            data['sort_key'] = props.sort;
        }
        if (props.noDownload) {
            serverPost(getApiUrl(props.exportUrl), data, { noJson: true }).then((res) => {
                props.onNoDownloadSuccess(res)
            })
        } else {
            serverPost(getApiUrl(props.exportUrl), data, { noJson: true }).then((res) => {
                downloadBlob(res, filename);
            })
        }
        props.onClose();
    }

    return (
        <BaseModal {...props} size="lg">
            <BaseModal.Header>
                <BaseModal.Title>{ props.title }</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.Body>
                <div className="checkbox check-success py-2">
                    <input type="checkbox" name="fields[]" id="csvcheckall" value="csvcheckall" checked={selectedFields.includes("csvcheckall")} onChange={ (event) => updateChecked(event) }/>
                    <label htmlFor="csvcheckall">Select All</label>
                </div>
                {
                    _.map(fields, (fs, j) =>
                        <React.Fragment key={j}>
                            { fs.title && <p className={"text-sm font-semibold mt-3"}>{ fs.title }</p> }
                            <div className="flex flex-row flex-wrap">
                                {
                                    _.map(fs.fields, (f, i) =>
                                        <div className="grow-0 shrink-1 w-[170px]" key={i}>
                                            <div className="checkbox check-success py-1 text-sm text-wrap flex flex-row items-center">
                                                <input className="h-10" type="checkbox" name="fields[]" id={f.value} value={f.value} checked={selectedFields.includes(f.value)} onChange={ (event) => updateChecked(event) }/>
                                                <label htmlFor={f.value} className="text-wrap">{f.label}</label>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </React.Fragment>
                    )
                }
                <br/>{props.bodyText && <div className="body1">{props.bodyText}</div>}
            </BaseModal.Body>
            <BaseModal.Footer>
                <Row>
                    <Col md="12" className="text-end">
                        <Button variant="link" onClick={() => props.onClose()}>Close</Button>
                        <Button variant="primary" onClick={() => onExport(selectedFields)}>Export CSV</Button>
                    </Col>
                </Row>
            </BaseModal.Footer>
        </BaseModal>
    );

}

export default BaseCSVExportModal;
