import '../App.scss';
import React, { useEffect, useState } from 'react';
import {serverFetch, getUrl, subscribeToEvent} from "../helpers/server";
import { UserContext } from "../helpers/common";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const _ = require('lodash');

function OptionalAuthorization(props) {
    const isLoggedIn = localStorage.getItem("auth_token") !== null;
    const navigate = useNavigate();
    const [loadingUserInfo, setLoadingUserInfo] = useState(true);
    const [userInfo, setUserInfo] = useState({});
    const allReady = !loadingUserInfo;

    useEffect(() => {
        fetchUserData();
        subscribeToEvent("user_settings", (data) => {
            fetchUserData(true)
        });
    }, []);

    useEffect(() => {
        if (!allReady) {
            return;
        }
    }, [allReady, navigate]);

    const fetchUserData = (skipCache) => {
        const handleError = (error) => {
            setLoadingUserInfo(false);
        }

        const authToken = localStorage.getItem("auth_token");
        if (!authToken) {
            setLoadingUserInfo(false);
        } else {
            serverFetch(getUrl(`/users/current`), { skipCache }, handleError).then((res) => {
                if (res) {
                    setUserInfo(res);
                }
                setLoadingUserInfo(false);
            })
        }
    }

    let isSuperUser = false
    let isMapleUser = false
    if (!_.isNil(userInfo)) {
        if (userInfo.email && _.endsWith(userInfo.email, "@maplebilling.com") && userInfo.verified) {
            isMapleUser = true;
        }
        if (!_.isEmpty(userInfo) && userInfo.is_super_user) {
            isSuperUser = true;
            isMapleUser = true;
        }
    }

    return (
        <UserContext.Provider value={{
            userInfo,
            isMapleUser: isMapleUser,
            isSuperUser: isSuperUser,
            isLoggedIn: !_.isEmpty(userInfo),
        }}>
        {
            allReady && <Outlet />
        }
        </UserContext.Provider>
    );
}

export default OptionalAuthorization;
