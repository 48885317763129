import '../App.scss';
import React, {useEffect, useState, forwardRef, useImperativeHandle, useContext, createRef} from 'react';
import BaseForm from './BaseForm';
import DeleteButton from './DeleteButton';
import { Row, Col } from 'react-bootstrap';
import { renderDescriptionForItemPricing, BaseContext, UserContext } from '../helpers/common';
import { useTranslation } from 'react-i18next';
import {serverPost} from "../helpers/server";
import Link from "./Link";
import ProductPricingInput from "./ProductPricingInput";
import {PlusIcon} from "@heroicons/react/20/solid";
const _ = require('lodash');

const ProductPricingSelection = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        validate(allFields) {
            return validate(allFields);
        },
        onFieldChange,
        getPricingSelectionFields(allFields) {
            return getPricingSelectionFields(allFields);
        }
    }));

    const { getApiUrl, company } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [coupons, setCoupons] = useState([]);
    const [allProductPricings, setAllProductPricings] = useState([]);
    const [selectedProductPricingsList, setSelectedProductPricingsList] = useState([[]]);
    const [editable, setEditable] = useState(true);
    const [allowDiscounts, setAllowDiscounts] = useState(true);
    const [requireRecurring, setRequireRecurring] = useState(false);
    const [billableItems, setBillableItems] = useState([]);
    const [discountsTypeMap, setDiscountsTypeMap] = useState({});

    const [numberOfPhases, setNumberOfPhases] = useState(1);
    const [phaseRefs, setPhaseRefs] = useState([]);

    useEffect(() => {
        let refs = [];
        for (let i = 0; i < numberOfPhases; i++) {
            refs.push(createRef());
        }
        setPhaseRefs(refs);
        setSelectedProductPricingsList(prevList => {
            const newList = [...prevList];
            if (newList.length < numberOfPhases) {
                newList.push([])
            }
            return newList.slice(0, numberOfPhases);
        })
    }, [numberOfPhases]);

    useEffect(() => {
        setAllProductPricings(props.productPricings);
    }, [props.productPricings])

    useEffect(() => {
        setCoupons(props.coupons);
    }, [props.coupons])

    useEffect(() => {
        setAllowDiscounts(props.allowDiscounts);
    }, [props.allowDiscounts])

    useEffect(() => {
        if (_.isNil(props.editable)) {
            setEditable(true);
        } else {
            setEditable(props.editable);
        }
    }, [props.editable])

    useEffect(() => {
        setRequireRecurring(props.requireRecurring || false);
    }, [props.requireRecurring])

    useEffect(() => {
        const selectedIds = props.preselectedProductPricingIds || [];
        const selectPricings = []
        _.each(selectedIds, (id) => {
            const pp = _.find(allProductPricings, (p) => p.id === id);
            if (pp) {
                selectPricings.push(pp);
            }
        })
        setSelectedProductPricingsList([selectPricings]);
    }, [allProductPricings, props.preselectedProductPricingIds])

    useEffect(() => {
        serverPost(getApiUrl("/billable/items/find"), {}).then((res) => {
            setBillableItems(_.filter(res, (r) => !r.standard));
        });
    }, [])

    useEffect(() => {
        const initialFields = props.initialFields
        if (initialFields) {
            if (initialFields.discounts) {
                setDiscountsTypeMap(_.mapValues(initialFields.discounts, (d) => d.type));
            }
        }
    }, [props.initialFields])


    const onFieldChange = (name, value) => {
        if (_.startsWith(name, "discounts.") && _.endsWith(name, ".type")) {
            setDiscountsTypeMap(prevMap => {
                const newMap = _.cloneDeep(prevMap)
                newMap[name.split(".")[1]] = value;
                return newMap;
            })
        }
    }

    const validate = (allFields) => {
        if (numberOfPhases > 0) {
            for (let i = 0; i < phaseRefs.length; i++) {
                if (phaseRefs[i].current) {
                    const validationError = phaseRefs[i].current.validate(allFields)
                    if (validationError) {
                        return validationError
                    }
                }
            }
        }
        return null;
    }

    const getPricingSelectionFields = (allFields) => {
        let baseConfig = {}
        let changes = [];
        for (let i = 0; i < phaseRefs.length; i++) {
            if (phaseRefs[i].current) {
                const selectionFields = phaseRefs[i].current.getPricingSelectionFields(allFields)
                if (i === 0) {
                    baseConfig = selectionFields;
                } else {
                    changes.push(selectionFields);
                }
            }
        }

        // baseConfig.scheduled_changes = changes;
        return baseConfig
    }

    const onPhaseDelete = (index) => {
        setNumberOfPhases(prev => prev - 1);
    }

    const showPhases = false;
    return (
        <div className="mb-4">
            <span className="body2">Pricing Details</span>
            <div className="mt-1 flex flex-col gap-3">
                {
                    _.map(_.range(numberOfPhases), (phase, i) =>
                        <ProductPricingSelectionPhase
                            ref={phaseRefs[i]}
                            key={i} prefix={phase}
                            billableItems={billableItems}
                            allowDiscounts={allowDiscounts}
                            initialFields={props.initialFields && props.initialFields.phases && props.initialFields.phases[i]}
                            allowMinimumSpend={props.allowMinimumSpend}
                            editable={editable}
                            requireRecurring={requireRecurring}
                            menuPlacement={props.menuPlacement}
                            showDuration={false}
                            canDeletePhase={false}
                            preselectedProductPricings={selectedProductPricingsList[phase]}
                            onSelectionChange={(pps) => {
                                if (props.onSelectionChange) {
                                    props.onSelectionChange(phase, pps)
                                }
                            }}
                            excludeBundlePricing={props.excludeBundlePricing}
                            extraQueryFilters={props.extraQueryFilters}
                            onPhaseDelete={() => onPhaseDelete(i)}
                        />
                    )
                }
            </div>
            {
                showPhases &&
                <div className="mt-2 mb-4 bg-slate-50 p-3 rounded-md border-1 border-slate-200">
                    <div className="flex flex-row items-center">
                        <Link onClick={() => setNumberOfPhases(prev => prev + 1)}>
                            <div className="text-sm flex flex-row gap-1"><PlusIcon className="w-5 h-5"/><span>Add Phase</span></div>
                        </Link>
                    </div>
                </div>
            }
        </div>
    )
})

const ProductPricingSelectionPhase = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        validate(allFields) {
            return validate(allFields);
        },
        getPricingSelectionFields(allFields) {
            return getPricingSelectionFields(allFields);
        }
    }));

    const { t } = useTranslation('common');
    const { getApiUrl, company } = useContext(BaseContext);
    const [selectedProductPricings, setSelectedProductPricings] = useState([]);
    const [allowDiscounts, setAllowDiscounts] = useState(props.allowDiscounts);
    const [billableItems, setBillableItems] = useState(props.billableItems);
    const [editable, setEditable] = useState(props.editable);
    const [showAddMinimumSpendInput, setShowAddMinimumSpendInput] = useState(false);

    useEffect(() => {
        setAllowDiscounts(props.allowDiscounts)
    }, [props.allowDiscounts]);

    useEffect(() => {
        if (props.onSelectionChange) {
            props.onSelectionChange(selectedProductPricings);
        }
        if (_.isEmpty(selectedProductPricings)) {
            setShowAddMinimumSpendInput(false);
        }
    }, [selectedProductPricings]);

    useEffect(() => {
        setSelectedProductPricings(props.preselectedProductPricings)
    }, [props.preselectedProductPricings]);

    const validate = (allFields) => {
        if (selectedProductPricings.length === 0) {
            return "Please select at least one product price."
        }
        const uniqueCurrencies = _.uniq(_.map(selectedProductPricings, (pp) => pp.currency));
        if (uniqueCurrencies.length > 1) {
            return "Multiple currencies are not permitted together."
        }
        if (props.requireRecurring) {
            const recurringProductPricings = _.filter(selectedProductPricings, (pp) => pp.type !== "ONETIME_PRICING");
            if (recurringProductPricings.length === 0) {
                return "Please select at least one recurring price.";
            }
        }
        return null;
    }

    const getPricingSelectionFields = (allFields) => {
        let discounts = [];
        _.each(allFields.phases[props.prefix].discounts, (value, key) => {
            if (parseFloat(value.percentage) > 0) {
                discounts.push({
                    name: "Discount",
                    type: "DISCOUNT_PERCENT",
                    customer_id: allFields.customer.id,
                    percent: parseFloat(value.percentage)/100,
                    state:"ACTIVE",
                    item_pricing_id: key
                })
            }
        })
        let onetime_items = []
        let recurring_pmp_ids = [];
        _.each(selectedProductPricings, (p, j) => {
            if (p.type !== "ONETIME_PRICING") {
                // Recurring price
                _.each(p.product_metric_pricings, pmp => {
                    recurring_pmp_ids.push(pmp.id)
                });
                return;
            }
            const pmp_id = p.product_metric_pricings[0].id;
            onetime_items.push({
                product_pricing_id: p.id,
                aggregate: parseFloat(allFields.phases[props.prefix].config[String(pmp_id)].num_licenses)
            })
        })
        let configItems = [];
        _.each(allFields.phases[props.prefix].config, (v, k) => {
            if (_.includes(recurring_pmp_ids, k)) {
                configItems.push({
                    ...v,
                    num_licenses: parseFloat(v.num_licenses || "0"),
                    minimum_units: parseFloat(v.minimum_units || "0"),
                    product_metric_pricing_id: k
                })
            }
        })
        return {
            currency: selectedProductPricings[0].currency,
            config_items: configItems,
            product_pricing_ids: _.map(_.filter(selectedProductPricings, (a) => a.type !== "ONETIME_PRICING"), (p) => p.id),
            onetime_items: onetime_items,
            discounts: discounts,
            minimum_spend: allFields.phases[props.prefix].minimum_spend ? {
                value_in_cents: parseFloat(allFields.phases[props.prefix].minimum_spend) * 100,
                currency: selectedProductPricings[0].currency
            } : { value_in_cents: 0, currency: selectedProductPricings[0].currency }
        }
    }

    const onProductProductPricingDelete = (value) => {
        setSelectedProductPricings(prevSelectedProductPricings => {
            return _.filter(prevSelectedProductPricings, (p) => p.id !== value);
        })
    }

    const selectedProductPricingIds = _.map(selectedProductPricings, (pp) => pp.id);

    const planLengthOptions = [
        { value: "DAY", label: "Day" },
        { value: "WEEK", label: "Week" },
        { value: "MONTH", label: "Month" },
        { value: "QUARTER", label: "Quarter" },
        { value: "BI_ANNUAL", label: "Bi-Annual" },
        { value: "YEAR", label: "Year" },
    ]

    const showMinimumField = showAddMinimumSpendInput || (props.initialFields && props.initialFields.minimum_spend && props.initialFields.minimum_spend > 0) || false;
    return (
        <div className="bg-slate-50 py-2 px-3 rounded-md border-1 border-slate-200">
            {
                props.showDuration &&
                <div className="flex flex-row gap-2">
                    <span className="text-md font-semibold text-gray-500">Start Date</span>
                    <span className="text-md font-semibold text-gray-500">-></span>
                    <Col md="5">
                    <BaseForm.InputGroup label="Duration">
                        <BaseForm.Number name="term.count" min="1" />
                        <BaseForm.Divider />
                        <BaseForm.SingleSelect
                            name="term.frequency" options={planLengthOptions} showSearch={false}
                            fullWidth
                        />
                    </BaseForm.InputGroup>
                    </Col>
                    { props.canDeletePhase && <DeleteButton onDelete={props.onPhaseDelete} /> }
                </div>
            }
            <div>
            {
                _.map(selectedProductPricings, (pp, i) => {
                    const pmps = pp.product_metric_pricings;
                    return (
                        <div key={i}>
                            <Row>
                                <Col md="12">
                                    <span className="body2">{ pp.product.name }</span>
                                    <DeleteButton onDelete={() => onProductProductPricingDelete(pp.id)} />
                                </Col>
                            </Row>
                            {
                                _.map(pmps, (pmp, j) =>
                                    <Row key={j} className="metric-pricing-row">
                                        <Col md="5">
                                            { renderDescriptionForItemPricing(pmp.item_pricing, true, pmp.item, billableItems) }
                                        </Col>
                                        {
                                            pmp.metric.type === "LICENSE_METRIC" &&
                                            <BaseForm.Input colSpan={allowDiscounts ? "5": "7"} name={`phases.${props.prefix}.config.${pmp.id}.num_licenses`} label={t('common.quantity')} type="number" step="0.01" min="0" required />
                                        }
                                        {
                                            pmp.metric.type === "ONETIME_METRIC" &&
                                            <BaseForm.Input colSpan={allowDiscounts ? "5": "7"} name={`phases.${props.prefix}.config.${pmp.id}.num_licenses`} label="One time" type="number" step="0.01" min="0" required />
                                        }
                                        {
                                            pmp.metric.type === "CUSTOM_METRIC" &&
                                            <Col md={allowDiscounts ? "3": "4"} className="d-flex flex-column gap-2">
                                                <div className="body2">Metric</div>
                                                { pmp.metric.name }
                                            </Col>
                                        }
                                        {
                                            pmp.metric.type === "CUSTOM_METRIC" &&
                                            <BaseForm.Input colSpan={allowDiscounts ? "2": "3"} name={`phases.${props.prefix}.config.${pmp.id}.minimum_units`} label="Minimum (optional)" type="number" step="0.01" min="0" />
                                        }
                                        {
                                            allowDiscounts &&
                                            <BaseForm.Input colSpan="2" name={`phases.${props.prefix}.discounts.${pmp.item_pricing.id}.percentage`} label="Discount %" type="number" min="0" step="0.01" max="100" />
                                        }
                                    </Row>
                                )
                            }
                        </div>
                    )
                })
            }
            </div>
            {
                !_.isEmpty(selectedProductPricings) &&
                <div className="mt-1"></div>
            }
            {
                editable &&
                    <Row>
                        <Col lg="6">
                            <ProductPricingInput
                                excludeBundlePricing={props.excludeBundlePricing}
                                extraQueryFilters={props.extraQueryFilters}
                                billableItems={billableItems}
                                setSelectedProductPricings={setSelectedProductPricings}
                                selectedProductPricings={selectedProductPricings}
                            />
                        </Col>
                    </Row>
            }
            {
                !_.isEmpty(selectedProductPricings) && props.allowMinimumSpend && !showMinimumField &&
                <div className="mt-3">
                    <Link onClick={() => setShowAddMinimumSpendInput(true)}>
                        <span className="text-sm">Add Minimum Spend</span>
                    </Link>
                </div>
            }
            {
                !_.isEmpty(selectedProductPricings) && props.allowMinimumSpend && showMinimumField &&
                <div>
                    <Row>
                        <BaseForm.Input
                            colSpan="6" name={`phases.${props.prefix}.minimum_spend`} label={`Minimum Spend (in ${selectedProductPricings[0].currency})`}
                            type="number" step="0.01" min="0" placeholder="0.00" />
                    </Row>
                </div>
            }
        </div>
    )
})

export default ProductPricingSelection;
